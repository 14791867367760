import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoLabel: {
    marginTop: '0 !important'
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class PackingQA extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      order_barcode: null,
      sku: null,
      note: null,
    };

    this.temp_data = {
      order_id: '',
      method: '',
      sku_map: null,
      upc_map: null,
      barcode_map: {},
      table_keys: []
    };

    this.state = {
      loading: false,
      items: null,
      qty: 1,
      qa_note: '',
      category: '',
    };

    document.title = "Packing QA";
  }

  resetPage = () => {
    this.temp_data = {
      order_id: '',
      method: '',
      sku_map: null,
      upc_map: null,
      table_keys: []
    };

    if (this.input_refs.order_barcode) {
      this.input_refs.order_barcode.value = '';
      this.input_refs.order_barcode.focus();
    }

    this.setState({
      items: null,
      qty: 1,
      qa_note: '',
      category: '',
    });
  }

  matchSKU = () => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]){
      matched_id = this.temp_data.sku_map[scanned_val];
    } else if (this.temp_data.upc_map[scanned_val]) {
      matched_id = this.temp_data.upc_map[scanned_val];
    } else {
      this.context.alert(`The scanned item ${scanned_val} is not in this Order.`);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }  
      return;
    }

    let new_items = Object.assign({}, this.state.items);
    let new_item = Object.assign({}, new_items[matched_id]);
    let new_qty = 1 + parseInt(new_item.received);
    if (new_qty > parseInt(new_item.quantity)) {
      this.context.alert("Item has finished packing, scan another one!");
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }  
      return;
    }
    new_item.received = new_qty;
    new_items[matched_id] = new_item;
    // move scanned to top
    let new_keys = [];
    new_keys.push(matched_id);
    for (let id of this.temp_data.table_keys) {
      if (id !== matched_id) new_keys.push(id);
    }
    this.temp_data.table_keys = new_keys;
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }  
    this.setState({items: new_items});
  }
  handleSKUScan = _.debounce(this.matchSKU, 150);
  orderSearch = () => {
    let keyword = utils.formatString(this.input_refs.order_barcode.value);
    if (!keyword) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/packingqa/${keyword}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.temp_data.cartons = [];
    this.setState({loading: true, qa_note: '', category: '',});
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    if (data.order.length !== 0) {
      this.temp_data.order_id = data.order[0].order_id; // save order barcode  
      this.temp_data.method = data.order[0].method; // save order barcode
    }
    for (let item of data.order) {
      let id = item.item_id;
      if (!item.received) item.received = 0;
      items[id] = item;

      let sku = item.sku;
      let upc = item.upc;
      if (!skus[sku]) {
          skus[sku] = id;
      }
      if (!upcs[upc]) {
          upcs[upc] = id;
      }
      keys.push(id);
    }
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.setState({items: items});
  }
  orderBarcodeScan = _.debounce(this.orderSearch, 150);

  submitPackingQA = () => {
    const {items, qa_note, category} = this.state;

    let err = '';
    if (!qa_note) err += 'Please enter QA Note!\n';
    if (!category) err += 'Please select Category!\n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/packingqa`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id: this.temp_data.order_id,
        comment: qa_note,
        category: category,
        items: items
      }
    });
    this.temp_data.cartons = [];
    this.setState({loading: true});
    req.then(this.submitPackingQASuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitPackingQASuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    // this.context.snackDisplay("Packing QA finished.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Packing QA finished.");
    this.resetPage();
  }
 
  renderOrderItemTable = () => {
    const { classes } = this.props;
    const { items } = this.state;
    if (!items) return null;
    let rows = [];
    for (let id of this.temp_data.table_keys) rows.push(items[id]);

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
        }
      },
      {
        key: 'name',
        label: 'ItemName',
      },
      {
        key: 'image',
        label: 'Image',
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      {
        key: 'color',
        label: 'Color',
      },
      {
        key: 'size',
        label: 'Size',
      },
      // {
      //   key: 'location',
      //   label: 'Location',
      //   contentNoWrap: true
      // },
      {
        key: 'lot',
        label: 'Lot',
      },
      {
        key: 'quantity',
        label: 'Quantity',
      },
      {
        key: 'received',
        label: 'Packed',
      },
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (item, displayProps) => {
        let color = '';

        let need = parseInt(item.quantity);
        let input = parseInt(item.quantity_input);
        let packed = parseInt(item.packed);

        switch (true) {
          case parseInt(item['quantity']) === parseInt(item['received']):
            // green quantity matched
            color = "green";
            break;
          case parseInt(item['received']) === 0:
            // white haven't start
            color = "";
            break;
          default:
            // yellow doesn't match
            // received too much
            if (parseInt(item['quantity']) < parseInt(item['received'])) color = "yellow";
            else color = 'light-red'; // received not enough

        }
        
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div style={{marginBottom: '1rem'}} className={classes.title} >
            <CustomInput
              labelText='SKU/UPC'
              formControlProps={{
                fullWidth: false,
                style: { marginRight: '1rem' },
                className: classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                defaultValue: '',
                onKeyUp: this.handleSKUScan,
                onPaste: (e)=>{
                  if (!utils.checkAllowCopy()) e.preventDefault();
                },
                inputRef: elem => this.input_refs.sku = elem
              }}
            />
          </div>

          <NewDataTable
            rows={rows}
            noPagination
            rowSettings={rowSettings}
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>  
      </GridItem>
    );
  }
  renderQAForm = () => {
    const {classes} = this.props;
    const { items } = this.state;
    if (!items || !this.temp_data.order_id) return null;

    return (
      <GridItem xs={12} sm={6}>
        <CardContainer>
          <CustomInput
            labelText='QA Note'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput,
              required: true,
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: this.state.qa_note,
              onChange: (e)=>this.setState({qa_note: e.target.value}),
            }}
          />

          <FormControl fullWidth required className={classes.selectInput}>
            <InputLabel shrink>Category</InputLabel>
            <Select
              value={this.state.category}
              onChange={(e)=>this.setState({category: e.target.value})}
            >
                <MenuItem value='Good'>Good</MenuItem>
                <MenuItem value='Item(s) missing from order'>Item(s) missing from order</MenuItem>
                <MenuItem value='Extra item(s) packed with order'>Extra item(s) packed with order</MenuItem>
                <MenuItem value='Incorrect item(s) packed with order'>Incorrect item(s) packed with order</MenuItem>
                <MenuItem value='Incorrect box/mailer used to pack order'>Incorrect box/mailer used to pack order</MenuItem>
                <MenuItem value='Missing Hazmat Label'>Missing Hazmat Label</MenuItem>
                <MenuItem value='Missing or not enough dunneage'>Missing or not enough dunneage</MenuItem>
                <MenuItem value='Missing Packing Slip'>Missing Packing Slip</MenuItem>
                <MenuItem value='Missing or not enough tape'>Missing or not enough tape</MenuItem>
                <MenuItem value='Missing shipping label'>Missing shipping label</MenuItem>
                <MenuItem value='SOP not followed'>SOP not followed</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={()=>{
            this.context.confirm("Are you sure to submit packing QA?", this.submitPackingQA);
          }} className={classes.button}>Submit Packing QA</Button>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <CustomInput
              labelText='Order Barcode'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                defaultValue: '',
                onKeyUp: this.orderBarcodeScan,
                inputRef: elem => this.input_refs.order_barcode = elem
              }}
            />
          </CardContainer>
        </GridItem>
 
        {this.renderQAForm()}
        {this.renderOrderItemTable()}   
      </Grid>
    );
  }
}
export default withStyles(styles)(PackingQA);
