import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
  ReversedIconButton
} from 'components';
import DataContext from 'context/Data'
import {
  ExposureNeg1
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class B2BScannerPick extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      batch_id: null,
      location: null,
      sku: null,
      cart: null,
    };

    this.temp_data = {
      batch_id: '',
      picking_location: '',
      verified_locaiton: null,
      verified_cart: null,
      sku_map: null,
      upc_map: null,
      barcode_map: {},
      table_keys: [],
    };

    this.state = {
      loading: false,
      items: {},
      type: '', // Primary or Air
      starting_location: '',
      picking_type: '', 
      forceUpdate: {},
    };

    document.title = "B2B Picking";
  }

  resetPage = () => {
    this.temp_data = {
      batch_id: '',
      picking_location: '',
      verified_locaiton: null,
      verified_cart: null,
      sku_map: null,
      upc_map: null,
      table_keys: [],
    };

    if (this.input_refs.batch_id) {
      this.input_refs.batch_id.value = '';
      this.input_refs.batch_id.focus();
    }

    this.setState({
      items: {},
      type: '',
      forceUpdate: {},
    });
  }

  handleSkuScanned = () => {
    let scanned_val = '';
    if (this.input_refs.sku) scanned_val = this.input_refs.sku.value;
    if (!scanned_val) return;

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    let item_id_list = {};
    if (this.temp_data.sku_map[scanned_val]) {
      item_id_list = this.temp_data.sku_map[scanned_val];
    } else if (this.temp_data.upc_map[scanned_val]) {
      item_id_list = this.temp_data.upc_map[scanned_val];
    } else {
      this.context.alert('Item not found on this Location or this item is not for picking.\n');
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
        return;
      }
    }
    let new_items = Object.assign({}, this.state.items);
    let new_item = null;
    let item_id = '';
    let error = '';
    for (let tote in item_id_list) {
      let old_item = Object.assign({}, new_items[item_id_list[tote]]);
      // check if item is skipped
      if (old_item.picked == -1) {
        if (!error) error += 'This item has been skipped, please scan and pick another item!\n';
        continue;
      }
      let new_qty = 1 + (parseInt(old_item.picked) || 0);
      if (new_qty > parseInt(old_item.quantity)) {
        error += 'Item has finished picking, scan another one. \n';
        continue;
      }
      error = '';
      item_id = item_id_list[tote];
      new_item = old_item;
      new_item.picked = new_qty; 
      break;
    }
    if (error) {
      this.context.alert(error);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }
      return;
    }
    new_items[item_id] = new_item;
    // move scanned to top
    let new_keys = [];
    new_keys.push(item_id);
    for (let id of this.temp_data.table_keys) {
      if (id != item_id) {
        new_keys.push(id);
      }
    }
    this.temp_data.table_keys = new_keys;
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }

    this.setState({items: new_items});
    let submit_result = this.checkPickingFinished(new_items);
    if (!submit_result.warning) {
      // if no item skipped, submit picking
      this.submitPickingOnce(submit_result.req_data);
    }
  }
  handleScan = _.debounce(this.handleSkuScanned, 150);
  checkPickingFinished = (new_items) => {
    const { batch_id } = this.temp_data;
    let items = new_items || this.state.items;

    let warning = '';
    let picked_items = [];
    for (let line_item_id in items) {
      let line_item = Object.assign({}, items[line_item_id]);
      // auto-skip un picked item and not finished item
      if (line_item.picked  != line_item.quantity) {
        if (line_item.picked != 0) {
          warning += `Item : ${line_item.sku} for tote : ${line_item.tote} has been scanned but didn't finish picking. \n`;
        } else {
          warning += `Item : ${line_item.sku} for tote : ${line_item.tote} is not scanned. \n`;
        }
        line_item.picked = -1; // auto-skip un picked item
      }
      picked_items.push(line_item);
    }
    return {
      req_data: {items: picked_items, batch_id, type: this.temp_data.type}, 
      warning
    };
  }

  batchSearch = () => {
    const {type, starting_location} = this.state;
    let keyword = utils.formatString(this.input_refs.batch_id.value);
    let err = '';
    if (!keyword) err += 'Please scan Batch Id. \n';
    if (!type) err += 'Please select Picking Type. \n';
    if (!starting_location) err += 'Please scan the Starting Location. \n';
    if (err) {
      this.context.alert(err);
      return;
    }

    this.resetSubmitOnce();

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/loadPicking`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
          type: type,
          batch_id: keyword,
          starting_location: starting_location,
      }
    });
    this.setState({loading: true, items: {}});
    
    req.then(this.batchSearchSuccess.bind(this, keyword)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  batchSearchSuccess = (batch_id, resp) => {
    this.setState({loading: false});
    let result = resp.data.picking_list;
    if (resp.data.Error) {
      if (Array.isArray(resp.data.Error)) this.context.alert(resp.data.Error.join("\n"));
      else this.context.alert(resp.data.Error);
      return;
    }

    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    let locations = Object.keys(result);
    if (locations.length <= 0) {
      this.context.alert("No location information");
      return;
    }
    this.temp_data.batch_id = batch_id;
    this.temp_data.type = this.state.type;
    this.temp_data.picking_location = locations[0];
    for (let item of result[this.temp_data.picking_location]) {
      let id = `${item.sku}_${item.order_id}`;
      if (!item.picked) item.picked = 0;
      items[id] = item;
      let sku = item.sku;
      let upc = item.upc;
      let tote = item.tote;
      // allows duplicate sku and upc, use sku/upc + tote to identify
      if (sku) {
        if (skus[sku]) skus[sku][tote] = id;
        else skus[sku] = {[tote]: id}
      }
      if (upc) {
        if (upcs[upc]) upcs[upc][tote] = id;
        else upcs[upc] = {[tote]: id}
      }
      keys.push(id);
    }
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.setState({items: items, picking_type: resp.data.picking_type});
  }
  skipPicking = () => {
    let submit_result = this.checkPickingFinished();
    let warning = submit_result.warning;
    if (warning) {
      warning = 'The following items have been skipped (Se han omitido los siguientes elementos): \n' + warning;
      warning += 'Are you sure to submit? (¿Estás segura de enviar?)';
      warning = warning.split('\n').map((msg, index)=><div key={index} style={{fontSize: '1.25rem'}}>{msg}</div>);
      this.context.confirm(<div>{warning}</div>, ()=>{
        this.submitPickingOnce(submit_result.req_data);
      });
    } else {
      this.submitPickingOnce(submit_result.req_data);
    }
  }
  submitPickingAJax = (data) => {
    data.start_location = this.temp_data.picking_location;
    
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/submitPicking`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitPickingSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitPickingOnce = _.once(this.submitPickingAJax);} // reset the submit picking once after the ajax call returns
  submitPickingOnce = _.once(this.submitPickingAJax)
  submitPickingSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let data = resp.data;

    if (data.finished) {
      // this.context.snackDisplay("Picking finished for selected level.", {hideCallback: ()=>{
      //   window.location.reload();
      // }});
      this.context.snackDisplay("Picking finished or no un-picked locations after your start location.");
      this.resetPage();
    } else {
      let items = {};
      let skus = {};
      let upcs = {};
      let keys = [];
      let locations = Object.keys(data);
      if (locations.length <= 0) {
        this.context.alert("No location information");
        return;
      }
      this.temp_data.picking_location = locations[0];
      for (let item of data[this.temp_data.picking_location]) {
        let id = `${item.sku}_${item.order_id}`;
        if (!item.picked) item.picked = 0;
        items[id] = item;
        let sku = item.sku;
        let upc = item.upc;
        let tote = item.tote;
        // allows duplicate sku and upc, use sku/upc + tote to identify
        if (sku) {
          if (skus[sku]) skus[sku][tote] = id;
          else skus[sku] = {[tote]: id}
        }
        if (upc) {
          if (upcs[upc]) upcs[upc][tote] = id;
          else upcs[upc] = {[tote]: id}
        }
        keys.push(id);
      }
      this.temp_data.sku_map = skus;
      this.temp_data.upc_map = upcs;
      this.temp_data.table_keys = keys;
      this.setState({items: items});
    }
  }

  renderPickingTable = () => {
    const { classes } = this.props;
    const { items, picking_type } = this.state;
    const { table_keys } = this.temp_data;

    // focus sku input
    _.delay(()=>{
      if (this.input_refs.sku && picking_type == 'B2CPICKING') this.input_refs.sku.focus();
    }, 0);

    if (!items || _.isEmpty(items) || table_keys.length === 0) return null;
    let rows = [];
    for (let index = 0; index < this.temp_data.table_keys.length; index++) {
      rows.push(items[this.temp_data.table_keys[index]]);
    }

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true,
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
        }
      },
      {
        key: 'tote',
        label: 'Tote',
        render: (val, key, row, index)=>{
          return index > 0 ? val : <span style={{fontSize: '2rem', fontWeight: 'bold'}}>{val}</span>
        }
      },
      {
        key: 'quantity',
        label: 'Quantity',
      },
      {
        key: 'picked',
        label: 'Scanned Qty',
        // render: (val)=>val===-1 ? 'Skipped' : val,
        render: (val, key, row)=>{
          if (picking_type == 'B2BPICKING') {
            return (
              <CustomInput
                labelText=''
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onChange: (e)=>{
                    if (e.target.value > row.quantity) {
                      this.context.alert(`The max quantity you need to pick is ${row.quantity}!`);
                      return;
                    }
                    row.picked = e.target.value; 
                    let submit_result = this.checkPickingFinished(items);
                    if (!submit_result.warning) {
                      // if no item skipped, submit picking
                      this.submitPickingOnce(submit_result.req_data);
                    }
                    this.setState({items: items})
                  },
                  value: row.picked ? row.picked : '',
                  type: 'number',
                  inputProps: {
                    min: 0,
                    style: {marginTop: '-1rem'},
                    max: row.quantity,
                    step: 1
                  },
                }}
              />
            );
          } else {
            return (
              <span style={{display: "inline-flex", alignItems: 'center'}}>
                {val}
                <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
                    let new_items = Object.assign({}, items);
                    let old_qty = parseInt(row.picked);
                    let new_qty = old_qty -1;
                    if (new_qty < 0) {
                        this.context.alert("The quantity is already 0, can't minus 1.");
                        return;
                    }
                    row['picked'] = new_qty;
                    new_items[`${row.sku}_${row.order_id}`] = row;
                    this.setState({items: new_items});
                }} variant="fab" color="secondary" aria-label="Decrease">
                    <ExposureNeg1 style={{color: 'white'}}/>
                </ReversedIconButton>
            </span>);
          }
        },
      },
      // {
      //   key: 'picking_status',
      //   label: 'Action',
      //   render: (val, key, row)=>{
      //     return (
      //       (row.picked !== -1) ? <Button color="secondary" onClick={()=>{
      //         this.context.confirm("Are you sure to skip picking for this item?", {onConfirm: ()=>{
      //           let id = row.sku + '_' + row.order_id;
      //           let new_item = items[id];
      //           new_item.picked = -1;
      //           this.setState({items: items});
      //         }});
      //       }}>Skip Picking</Button>
      //       : <Button onClick={()=>{
      //         this.context.confirm("Are you sure to Unskip picking for this item?", {onConfirm: ()=>{
      //           let id = row.sku + '_' + row.order_id;
      //           let new_item = items[id];
      //           new_item.picked = 0;
      //           this.setState({items: items});
      //         }});
      //       }}>
      //         UnSkip Picking
      //       </Button>
      //     );
      //   },
      // },
    ];
    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (data, displayProps) => {
        // todo, update color logic
        let color = '';
        switch (true) {
          case data['picked'] === -1:
              // Skipped item need another color
              color = "blue";
              break;
          case parseInt(data['quantity']) === parseInt(data['picked']):
              // green quantity matched
              color = "green";
              break;
          case parseInt(data['picked']) === 0:
              // white haven't start
              color = "";
              break;
          default:
              // yellow doesn't match
              // received too much
              if (data['quantity'] < data['picked']) color = "yellow";
              else color = 'light-red'; // received not enough
        }
        if (!displayProps) displayProps = {className: ''};
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div>
            <Typography variant="h2" style={{marginBottom: '.5rem'}}>
              {this.temp_data.picking_location}
            </Typography>
            <div style={{width: '100%',display: 'inline-flex'}}>
              <div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>
                <CustomInput
                  labelText='SKU/UPC'
                  formControlProps={{
                      fullWidth: false,
                      style: {marginLeft: '.25rem', marginRight: '.75rem'},
                      className: classes.customInput
                  }}
                  labelProps={{
                      shrink: true,
                  }}
                  inputProps={{
                      onKeyUp: this.handleScan,
                      onPaste: (e)=>{
                        if (!utils.checkAllowCopy()) e.preventDefault();
                      },
                      inputRef: elem=>this.input_refs.sku = elem
                  }}
                />
              </div>
              <div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>{<Button style={{marginLeft: ".25rem"}} onClick={this.skipPicking}>Skip Picking</Button>}</div>
            </div>

            <NewDataTable
              rows={rows}
              // rowHeight={50} 
              maxHeight={500}
              noPagination
              rowsPerPage={50}
              rowSettings={rowSettings}
              columns={columns}
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
 
  render() {
    const { classes } = this.props;
    const { loading, type, starting_location} = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <FormControl required fullWidth className={classes.selectInput}>
              <InputLabel shrink htmlFor="type">Picking Type</InputLabel>
              <Select
                value={type}
                disabled={!!type}
                onChange={(e)=>{this.setState({type: e.target.value})}}
              >
                <MenuItem value='Primary'>Primary</MenuItem>
                <MenuItem value='Reserved'>Reserved</MenuItem>
              </Select>
            </FormControl>

            <CustomInput
              labelText='Batch id'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                defaultValue: '',
                disabled: loading,
                inputRef: elem => this.input_refs.batch_id = elem
              }}
            />

            <CustomInput
              labelText='Starting Location'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                disabled: loading,
                value: starting_location,
                placeholder: 'Scan the location you want to start with',
                onChange: (e)=>this.setState({starting_location: e.target.value})
              }}
            />

            <Button onClick={this.batchSearch} className={classes.button}>Search</Button>
          </CardContainer>
        </GridItem>

        {this.renderPickingTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(B2BScannerPick);
