import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class PhysicalCountAdmin extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      sku: null,
      location: null,
    };
    this.temp_data = {
      location_name: '',
      location_id: '',
      sku_map: {},
      upc_map: {},
      barcode_map: {},
      key_array: [],
    };

    this.state = {
      loading: false,
      round: '',
      location_items: null,
    };

    document.title = "Physical Count";
  }

  reset = () => {
    this.setState({location_items: null});
    this.temp_data = {
      location_name: '',
      location_id: '',
      sku_map: {},
      upc_map: {},
      key_array: [],
    };
  }
  checkLocation = () => {
    let scanned_val = utils.formatString(this.input_refs.location.value);
    if (!scanned_val || scanned_val.length < 3) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/cycle/${scanned_val}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.checkLocationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  locationScan = _.debounce(this.checkLocation, 150);
  checkLocationSuccess = (resp) => {
    this.setState({loading: false});
    if (this.input_refs.location) {
      this.input_refs.location.value = '';
      this.input_refs.location.focus();
    }  
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      this.context.alert("Location Not Found!");
      return;
    }

    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    for (let item of resp.data) {
        if (!item.item_id) continue;

        item.quantity_input = 0;
        let id = item.item_id;
        let sku = item.sku ? ('' + item.sku).toUpperCase() : "";
        let upc = item.upc ? ('' + item.upc).toUpperCase() : "";
        if (!skus[sku]) {
            skus[sku] = id;
        }
        if (!upcs[upc]) {
            upcs[upc] = id;
        }
        items[item.item_id] = item;
        keys.push(id);
    }
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.key_array = keys;
    this.temp_data.location_id = resp.data[0].location_id;
    this.temp_data.location_name = resp.data[0].location;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.setState({location_items: items, loading: false});
  }
  matchSKU = () => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;
    scanned_val = scanned_val.toUpperCase();

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) {
      matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
    } else if (this.temp_data.upc_map[scanned_val]) {
      matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
    } else {
      this.verifySku(scanned_val);
      return;
    }
    let items = Object.assign({}, this.state.location_items);
    let newItem = Object.assign({}, items[matched_id]);
    let newQty = 1 + (parseInt(newItem.quantity_input) || 0);
    newItem['quantity_input'] = newQty;
    items[matched_id] = newItem;
    // Move the scanned row to top
    
    let newKeys = [];
    newKeys.push(matched_id);
    for (let id of this.temp_data.key_array) {
      if (id != matched_id) {
        newKeys.push(id);
      }
    }
    this.temp_data.key_array = newKeys;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({location_items: items});
  }
  handleSKUScan = _.debounce(this.matchSKU, 150);

  verifySku = (scanned_val) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving/${scanned_val}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.verifySkuSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  verifySkuSuccess = (resp) => {
    this.setState({loading: false});
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }  
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let verified_item = resp.data[0];
    let new_items = Object.assign({}, this.state.location_items);
    let new_keys = [verified_item.item_id]; // move matched to top
    if (new_items[verified_item.item_id]) {
      // if item already exists 
      let new_item = new_items[verified_item.item_id];
      new_item.quantity_input = 1 + (parseInt(new_item.quantity_input) || 0);
      // move matched to top
      for (let id of this.temp_data.key_array) {
        if (id != verified_item.item_id) {
          new_keys.push(id);
        }
      }
    } else {
      // new item, insert
      verified_item.quantity_input = 1;
      verified_item.sku = verified_item.sku || "";
      verified_item.upc = verified_item.upc || "";
      new_items[verified_item.item_id] = verified_item;   
      if (!this.temp_data.sku_map[verified_item.sku]) this.temp_data.sku_map[('' + verified_item.sku).toUpperCase()] = verified_item.item_id;
      if (!this.temp_data.upc_map[verified_item.upc]) this.temp_data.upc_map[('' + verified_item.upc).toUpperCase()] = verified_item.item_id;
      // move matched to top
      new_keys = new_keys.concat(this.temp_data.key_array);
    }
    this.temp_data.key_array = new_keys;

    this.setState({location_items: new_items});
  }
  submit = () => {
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    let err = '';
    let { location_items, round} = this.state;
    if (!round) err += 'Round is required. \n';

    let submit_items = [];
    for (let item_id in location_items) {
      let count_item = Object.assign({}, location_items[item_id]); 
      count_item.lotcode = count_item.lotcode || '';
      submit_items.push(count_item);
    }

    if (err) this.context.alert(err);
    else {
      this.context.confirm("Are you sure you want to submit physical count?", this.submitCountOnce.bind(this, {
        location_id: this.temp_data.location_id,
        location_name: this.temp_data.location_name,
        items: submit_items,
        round,
        // item: JSON.stringify(receiving_items)
      }));
    }
  }
  submitAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/physicalcount`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitCountOnce}));
    this.setState({loading: true})
  }
  resetSubmitCountOnce = () => {this.submitCountOnce = _.once(this.submitAJax);} // reset the submit picking once after the ajax call returns
  submitCountOnce = _.once(this.submitAJax) 
  submitSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitCountOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Submit physical count success.");
    this.reset();
    if (this.input_refs.location) {
      this.input_refs.location.value = '';
      this.input_refs.location.focus();
    }  
  }

  renderLocationTable = () => {
    const { classes } = this.props;
    const { location_items } = this.state;

    if (!location_items) return null;

    let rows = [];
    for (let item_id of this.temp_data.key_array) {
      rows.push(location_items[item_id]);
    }

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      },
      {
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true
      },
      {
        key: 'system_quantity',
        label: 'System Quantity',
        contentNoWrap: true,
        render: (val, key, row)=>0
      },
      {
        key: 'quantity_input',
        label: 'Scanned',
        render: (val, key, row)=>{
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                onChange: (e)=>{
                  let new_val = e.target.value;
                  let new_items = Object.assign({},this.state.location_items);
                  let new_item = new_items[row.item_id]; 
                  if (new_val > 10000) {
                    this.context.confirm("You just entered a very large quantity, are you sure this is correct? (Acaba de ingresar una cantidad muy grande, ¿está seguro de que es correcto?)", ()=>{
                      new_item.quantity_input = new_val;
                      this.setState({location_items: new_items});
                    });
                  } else {
                    new_item.quantity_input = new_val;
                    this.setState({location_items: new_items});
                  }
                },
                value: row.quantity_input || '',
                type: 'number',
                inputProps: {
                  min: 0,
                  step: 1,
                }
              }}
            />
          );
        }
      },
      {
        key: 'lotcode',
        label: 'Lotcode',
        render: (val, key, row)=>{
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                onChange: (e)=>{
                  let new_val = e.target.value;
                  let new_items = Object.assign({},this.state.location_items);
                  let new_item = new_items[row.item_id]; 
                  new_item.lotcode = new_val;
                  this.setState({location_items: new_items});
                },
                value: row.lotcode || '',
              }}
            />
          );
        }
      },
    ];

    // _.delay(()=>{
    //   if (this.input_refs.sku) this.input_refs.sku.focus();
    // }, 0);

    return (
     <GridItem xs={12}>
      <CardContainer>

        <div className={classes.title} >
          <Typography variant="h6">
            {this.temp_data.location_name}
          </Typography>
          <div>       
            <CustomInput
              labelText='SKU/UPC'
              formControlProps={{
                  fullWidth: false,
                  style: {marginLeft: '.25rem', marginRight: '.75rem'},
                  className: classes.customInput
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  onKeyUp: this.handleSKUScan,
                  // onPaste: (e)=>{e.preventDefault();},
                  inputRef: elem=>this.input_refs.sku = elem
              }}
            />

            <Button style={{marginTop: '0.75rem'}} onClick={this.submit}>Submit Count</Button>
          </div>
        </div>   

        <NewDataTable
          rows={rows}
          // rowHeight={50} 
          maxHeight={500}
          // rowsPerPage={50}
          noPagination
          columns={columns}
        />
      </CardContainer>
     </GridItem>
    );
  }
  renderInputForm = () => {
    const {classes} = this.props;
    const {round} = this.state;

    if (!this.temp_data.location_id && round) {
      _.delay(()=>{
        if (this.input_refs.location) this.input_refs.location.focus();
      }, 0);  
    }
   
    return (
      <div>
        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Round</InputLabel>
          <Select
              value={round}
              onChange={(e)=>{this.setState({round: e.target.value})}}
          >
            <MenuItem value='1'>First</MenuItem>
            <MenuItem value='2'>Second</MenuItem>
            <MenuItem value='3'>Third</MenuItem>
          </Select>
        </FormControl>

        <CustomInput
          labelText='Location'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            defaultValue: '',
            onKeyUp: this.locationScan,
            inputRef: elem => this.input_refs.location = elem
          }}
        />
      </div>
    );
  }
  render() {
    const {} = this.state; 
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            {this.renderInputForm()}
          </CardContainer>
        </GridItem>

        {this.renderLocationTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(PhysicalCountAdmin);
