import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
	NewDataTable,
	CardContainer,
	Button,
	CustomInput,
	GridItem, 
	ReversedIconButton
} from 'components';
import DataContext from 'context/Data'
import {
	ExposureNeg1
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	nonSelectable: {
		userSelect: 'none'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	table: {
		minWidth: 700,
	},
	customInput: {
		margin: theme.spacing(0.5, 0),
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	title: {
		flex: '0 0 auto',
	},
	button: {
		marginTop: theme.spacing(1)
	},
	customInputContainer: {
		margin: '0 !important',
		padding: 0
	},
	customInputNoMargin: {
		margin: '0 !important',
		padding: 0
	},
	menuItemText: {
		whiteSpace: 'break-spaces',
		wordWrap: 'break-word',
		// ['@media (min-width:780px)']:
		[theme.breakpoints.down('md')]: {
			maxWidth: "400px",
		},
		[theme.breakpoints.between('md', 'lg')]: {
			maxWidth: "600px",
		},
		[theme.breakpoints.up('lg')]: {
			// maxWidth: "700px",
		},
	},
});

class PalletPutaway extends Component {
	static contextType = DataContext;

	static propTypes = {
		classes: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		
		this.input_refs = {
			pallet_id: null,
			location: null,
			sku: null,
		};

		this.temp_data = {
			pallet_id: '', // the only Flag that marks if the putaway work is under going
			putaway_locations: [], // the list of putaway ticket grouped by location
			working_index: 0, // the currenty index of location user working on
			working_location: '', // Name of location currently working on
			working_location_id: '', // id of location currently working on
			sku_map: null, // mapping sku to item_id for scan
			upc_map: null, // mapping upc to item_id for scan
			barcode_map: {},
			working_keys: [], // the list of item_ids + tote for items to be putaway at current location 
		};

		this.state = {
			loading: false,
			working_items: {}, // the items to be putaway at current location
			location_verified: false, // for each putaway location need to verify before working
		};

		document.title = "Pallet put-away";
	}

	resetPage = () => {
		this.temp_data = {
			pallet_id: '', 
			putaway_locations: [],
			working_index: 0,
			working_location: '', 
			working_location_id: '', 
			sku_map: null,
			upc_map: null,
			working_keys: [], 
		};

		if (this.input_refs.pallet_id) {
			this.input_refs.pallet_id.value = '';
			this.input_refs.pallet_id.focus();
		}

		this.setState({
			working_items: {},
			location_verified: false,
		});
	}

	matchScannedItem = () => {
		let scanned_val = '';
		if (this.input_refs.sku) scanned_val = this.input_refs.sku.value;
		if (!scanned_val) return;

		if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

		let item_id_list = {};
		if (this.temp_data.sku_map[scanned_val]) {
			item_id_list = this.temp_data.sku_map[scanned_val];
		} else if (this.temp_data.upc_map[scanned_val]) {
			item_id_list = this.temp_data.upc_map[scanned_val];
		} else {
			this.context.alert('Item not found at this Putaway Ticket Location.\n');
			if (this.input_refs.sku) {
				this.input_refs.sku.value = '';
				this.input_refs.sku.focus();
				return;
			}
		}
		let new_items = Object.assign({}, this.state.working_items);
		let new_item = null;
		let item_id = ''; // this actually is item_id + tote
		let error = '';
		for (let tote in item_id_list) {
			let old_item = Object.assign({}, new_items[item_id_list[tote]]);
			let new_qty = 1 + (parseInt(old_item.quantity_input) || 0);
			if (new_qty > parseInt(old_item.quantity)) {
				error += `SKU ${old_item.sku} at tote ${tote} has finished putaway at this location, scan another one. \n`;
				continue;
			}
			error = ''; // if same item at other tote unfinished, clear the finish error
			item_id = item_id_list[tote];
			new_item = old_item;
			new_item.quantity_input = new_qty; 
			break; // only the first matched one
		}
		if (error) {
			this.context.alert(error);
			if (this.input_refs.sku) {
				this.input_refs.sku.value = '';
				this.input_refs.sku.focus();
			}
			return;
		}
		new_items[item_id] = new_item;
		// move scanned to top
		let new_keys = [];
		new_keys.push(item_id);
		for (let id of this.temp_data.working_keys) {
			if (id != item_id) {
				new_keys.push(id);
			}
		}
		this.temp_data.working_keys = new_keys;
		if (this.input_refs.sku) {
			this.input_refs.sku.value = '';
			this.input_refs.sku.focus();
		}

		this.setState({working_items: new_items});
		let submit_result = this.checkScanFinished(new_items);
		if (!submit_result.warning) {
			// if no item skipped, submit putaway
			this.submitPutawayOnce(submit_result.items);
		}
	}
	handleSKUScan = _.debounce(this.matchScannedItem, 150);
	verifyLocation = () => {
		let scanned_val = '';
		if (this.input_refs.location) scanned_val = this.input_refs.location.value.trim();
		if (scanned_val === this.temp_data.working_location) {
			this.setState({location_verified: true});
		} else {
			this.context.alert(`Wrong location scanned. Please go to ${this.temp_data.working_location}`);
		}
		if (this.input_refs.location) this.input_refs.location.value = ''; // clear input
	}
	handleLocationScan = _.debounce(this.verifyLocation, 150);

	palletSearch = () => {
		let keyword = utils.formatString(this.input_refs.pallet_id.value);
		let err = '';
		if (!keyword) err += 'Please scan Pallet Id. \n';
		if (err) {
			this.context.alert(err);
			return;
		}

		let req = axios({
			method: 'get',
			url: `${utils.getBaseUrl('customer')}/putawayByTicket/${keyword}`,
			headers: {
				token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
			},
		});
		this.setState({loading: true, items: {}});
		
		req.then(this.palletSearchSuccess.bind(this, keyword)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
	}
	palletSearchSuccess = (pallet_id, resp) => {
		this.setState({loading: false});
		if (resp.data.Error) {
			if (Array.isArray(resp.data.Error)) this.context.alert(resp.data.Error.join("\n"));
			else this.context.alert(resp.data.Error);
			return;
		}
		if (!resp.data || resp.data.length == 0) {
			this.context.alert('No primary putaway location planned, there is not enough parimary empty location for this pallet.');
			return;
		}

		let location_verified = false;
		let working_index = 0; // reset working index
		let working_location = '';
		let working_location_id = '';
		let working_items = {};
		let skus = {};
		let upcs = {};
		let working_keys = [];
		let putaway_locations = resp.data;
		for (let item of putaway_locations[working_index]) {
			working_location = item.location;
			working_location_id = item.location_id;
			let id = `${item.item_id}_${item.tote}`;
			let tote = item.tote;
			let sku = item.sku;
			let upc = item.upc;
			item.quantity_input = 0; // init the scanned quantity of each item
			working_items[id] = item;
			working_keys.push(id);
			if (sku) {
				if (skus[sku]) skus[sku][tote] = id;
				else skus[sku] = {[tote]: id}
			}
			if (upc) {
				if (upcs[upc]) upcs[upc][tote] = id;
				else upcs[upc] = {[tote]: id}
			}
		}

		this.temp_data = Object.assign(this.temp_data, {
			pallet_id: pallet_id.replace(/[pP]/g, ''),
			putaway_locations,
			working_index,
			working_location,
			working_location_id,
			sku_map: skus,
			upc_map: upcs,
			working_keys,
		});

		// Some clients, for now it's only 204, 
		this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

		this.setState({working_items, location_verified});
	}
	skipItems = () => {
		let submit_result = this.checkScanFinished();
		if (submit_result.warning) {
			let warning = submit_result.warning;
			warning = warning.split('\n').map((msg)=><div style={{fontSize: '1.25rem'}}>{msg}</div>);
			this.context.confirm(<div>{warning}</div>, ()=>{
				if (submit_result.items.length > 0) this.submitPutawayOnce(submit_result.items); // call backend to submit items
				else this.finishCurrentLocation(); // no items scanned, no need o call backend, move to next location
			});
		} else {
			if (submit_result.items.length > 0) this.submitPutawayOnce(submit_result.items); // call backend to submit items
			else this.finishCurrentLocation(); // no items scanned, no need o call backend, move to next location
		}
	}
	checkScanFinished = (new_items) => {
		if (!new_items) new_items = this.state.working_items;
		let warning = '';
		let submit_items = [];
		for (let key in new_items) {
			let item = new_items[key];
			if (item.quantity != item.quantity_input) {
				warning += `Item : ${item.sku} ${item.tote ? `for tote : ${item.tote} ` : ' '} did not finish scanning (only ${item.quantity_input} out of ${item.quantity} scanned). \n`;
			}
			if (item.quantity_input && item.quantity_input > 0) submit_items.push(item); // skip un item with 0 quantity input
		}
		if (submit_items.length === 0) {
			warning = `Nothing has been scanned at this location, are you sure to skip this location?`;
		} else {
			if (warning) warning += `Some items of this location did not finish scanning, are you sure to partially submit?`;
		}
		
		return { warning, items: submit_items};
	}
	finishCurrentLocation = () => { // finish putaway of current location, move to the next one
		let { putaway_locations, working_index, } = this.temp_data;
		working_index += 1; // move to next location
		if (working_index >= putaway_locations.length) {
			// All location on the putaway ticket finished or skipped
			this.resetPage();
			this.context.snackDisplay("Putaway finished.");
		} else {
			// prepare next location
			let location_verified = false;
			let working_items = {};
			let working_location = '';
			let working_location_id = '';
			let working_keys = [];
			let sku_map = {};
			let upc_map = {};
			for (let item of putaway_locations[working_index]) { 
				working_location = item.location;
				working_location_id = item.location_id;
				let id = `${item.item_id}_${item.tote}`;
				let tote = item.tote;
				let sku = item.sku;
				let upc = item.upc;
				item.quantity_input = 0; // init the scanned quantity of each item
				working_items[id] = item;
				working_keys.push(id);
				if (sku) {
					if (sku_map[sku]) sku_map[sku][tote] = id;
					else sku_map[sku] = {[tote]: id}
				}
				if (upc) {
					if (upc_map[upc]) upc_map[upc][tote] = id;
					else upc_map[upc] = {[tote]: id}
				}
			}

			this.temp_data = Object.assign(this.temp_data, {
				working_index,
				working_location,
				working_location_id,
				sku_map,
				upc_map,
				working_keys,
			});

			this.setState({
				location_verified,
				working_items
			});
		}
	}
	submitPutawayAJax = (submit_items) => {
		const { pallet_id, working_location, working_location_id } = this.temp_data;

		this.setState({loading: true})
		let req = axios({
			method: 'post',
			url: `${utils.getBaseUrl('customer')}/movementpallet`,
			headers: {
				token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
			},
			data: {
				items: submit_items, // submit items is the items with quantity_input more than 0
				pallet_id,
				target_location: working_location,
				target_location_id: working_location_id
			},
		});

		req.then(this.submitPutawaySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
		this.setState({loading: true})
	}
	resetSubmitOnce = () => {this.submitPutawayOnce = _.once(this.submitPutawayAJax);}
	submitPutawayOnce = _.once(this.submitPutawayAJax)
	submitPutawaySuccess = (resp) => {
		this.setState({loading: false});
		this.resetSubmitOnce();
		if (resp.data.Error) {
			this.context.alert(resp.data.Error);
			return;
		}
		if (resp.data) {
			// submit success, finsh current and move to next
			this.finishCurrentLocation();
		} else {
			// submit failed, show message
			this.context.alert('Putaway failed, please try again');
		}
	}

	renderLocationTable = () => {
		const { classes } = this.props;
		const { working_items, location_verified } = this.state;
		const { pallet_id, working_keys } = this.temp_data;

		if (!pallet_id) return null;

		// focus input
		_.delay(()=>{
			if (location_verified) {
				if (this.input_refs.sku) this.input_refs.sku.focus();	// if loc verified, start scanning item
			} else {
				if (this.input_refs.location) this.input_refs.location.focus();	// if loc NOT verified, scan and verify location tag
			}
		}, 0);

		let rows = [];
		rows = working_keys.map((key)=>working_items[key]);

		let columns = [
			{
				key: 'sku',
				label: 'SKU',
				contentNoWrap: true,
				render: (val, key, row) => {
					return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
				}
			},
			{
				key: 'upc',
				label: 'UPC',
				contentNoWrap: true,
				render: (val, key, row) => {
					return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
				}
			},
			{
				key: 'category',
				label: 'Category',
				contentNoWrap: true,
			},  
			{
				key: 'tote',
				label: 'Tote',
				render: (val, key, row, index)=>{
					return index > 0 ? val : <span style={{fontSize: '2rem', fontWeight: 'bold'}}>{val}</span>
				}
			},
			{
				key: 'quantity',
				label: 'Quantity',
			},
			{
				key: 'quantity_input',
				label: 'Scanned Qty',
				render: (val, key, row)=>{
					return (
						<span style={{display: "inline-flex", alignItems: 'center'}}>
							{val}
							<ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
								let new_items = Object.assign({}, working_items);
								let old_qty = parseInt(row.quantity_input);
								let new_qty = old_qty -1;
								if (new_qty < 0) {
									this.context.alert("The quantity is already 0, can't minus 1.");
									return;
								}
								row['quantity_input'] = new_qty;
								new_items[`${row.item_id}_${row.tote}`] = row;
								this.setState({working_items: new_items});
							}} variant="fab" color="secondary" aria-label="Decrease">
									<ExposureNeg1 style={{color: 'white'}}/>
							</ReversedIconButton>
					</span>);
				},
			},
		];
		let rowSettings = {
			rowProps: {
				hover: false
			},
			classNameOnDisplay: (data, displayProps) => {
				// todo, update color logic
				let color = '';
				switch (true) {
					case data['quantity_input'] === -1:
							// Skipped item need another color
							color = "blue";
							break;
					case parseInt(data['quantity_input']) === parseInt(data['quantity']):
							// green quantity matched
							color = "green";
							break;
					case parseInt(data['quantity_input']) === 0:
							// white haven't start
							color = "";
							break;
					default:
							// yellow doesn't match
							// scanned too much
							if (data['quantity'] < data['quantity_input']) color = "yellow";
							else color = 'light-red'; // scanned not enough
				}
				if (!displayProps) displayProps = {className: ''};
				let classes = displayProps.className || '';
				classes = classes.split(' ');
				classes.push(color);
				displayProps.className = classes.join(' ');
				return displayProps;
			}
		};

		return (
			<GridItem xs={12}>
				<CardContainer>
					<div>
						<Typography variant="h2" style={{marginBottom: '.5rem'}}>
							{this.temp_data.working_location}
						</Typography>
						<div style={{width: '100%',display: 'inline-flex'}}>
							<div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>
								{
									!location_verified && <CustomInput
										labelText='Verify Location'
										formControlProps={{
											fullWidth: false,
											style: {marginLeft: '.25rem', marginRight: '.75rem'},
											className: classes.customInput
										}}
										labelProps={{
											shrink: true,
										}}
										inputProps={{
											defaultValue: '',
											disabled: location_verified,
											onKeyUp: this.handleLocationScan,
											onPaste: (e)=>{
												if (!utils.checkAllowCopy()) e.preventDefault();
											},
											inputRef: elem=>this.input_refs.location = elem
										}}
									/> 
								}
								
								{
									location_verified && <CustomInput
										labelText='SKU/UPC'
										formControlProps={{
											fullWidth: false,
											style: {marginLeft: '.25rem', marginRight: '.75rem',},
											className: classes.customInput
										}}
										labelProps={{
											shrink: true,
										}}
										inputProps={{
											onKeyUp: this.handleSKUScan,
											defaultValue: '',
											disabled: !location_verified,
											onPaste: (e)=>{e.preventDefault();},
											inputRef: elem=>this.input_refs.sku = elem
										}}
									/>
								}
							</div>
							<div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>{<Button style={{marginLeft: ".25rem"}} onClick={this.skipItems}>{location_verified ? 'Skip The Rest' : 'Skip this location'}</Button>}</div>
						</div>

						{
							location_verified &&
							<NewDataTable
								rows={rows} 
								maxHeight={500}
								noPagination
								rowSettings={rowSettings}
								columns={columns}
							/> 	
						}
					</div>
				</CardContainer>
			</GridItem>
		);
	}
 
	render() {
		const { classes } = this.props;
		const { loading } = this.state;

		if (!this.temp_data.pallet_id) {
			// focus input
			_.delay(()=>{
				if (this.input_refs.pallet_id) this.input_refs.pallet_id.focus();
			}, 0);
		}

		return (
			<Grid container spacing={2}>
				{this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
			
				<GridItem xs={12} sm={6}>
					<CardContainer>
						<CustomInput
							labelText='Pallet id'
							formControlProps={{
								fullWidth: true,
								required: true,
								className: classes.customInput,
							}}
							labelProps={{
								shrink: true,
							}}
							inputProps={{
								defaultValue: '',
								disabled: loading,
								inputRef: elem => this.input_refs.pallet_id = elem
							}}
						/>

						<Button onClick={this.palletSearch} className={classes.button}>Search</Button>
					</CardContainer>
				</GridItem>

				{this.renderLocationTable()}
			</Grid>
		);
	}
}
export default withStyles(styles)(PalletPutaway);
