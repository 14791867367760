import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import {Divider, ButtonGroup, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  ReversedIconButton,
  CustomInput,
  GridItem, 
  MultiSelect
} from 'components';
import EditTask from 'views/Dialogs/WarehouseTasks/Edit'
import EndTask from 'views/Dialogs/WarehouseTasks/End'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
	display: 'flex',
	flexWrap: 'wrap',
  },
  formControl: {
	margin: theme.spacing(1),
	minWidth: 120,
  },
  selectEmpty: {
	marginTop: theme.spacing(2),
  },
  table: {
	minWidth: 700,
  },
  tableImage: {
	height: '40px',
	width: '40px',
  },
  tableDetailImage: {
	height: '80px',
	width: '80px',
  },
  customInput: {
	margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
	overflowX: 'auto',
  },
  title: {
	flex: '0 0 auto',
  },
  buttonPushRight: {
	marginLeft: 'auto',
  },
  cardContainer: {
	// height: '800px',
	// width: '100%'
  },
  toolBar: {
	display: 'flex',
	paddingBottom: '.5rem',
	width: '100%'
  },
});

const taskDepartments = [
	'INBOUND', 'OUTBOUND B2C',  'OUTBOUND B2B', 'INVENTORY', 'OTHER'
];
// const taskTypes = {
//     INBOUND: [
// 		'Recording',
// 	],
//     'OUTBOUND B2C': [
// 		'Ticketing',
// 		'Tagging',
// 		'Picking',
// 		'Kitting',
// 		'Recording',
// 	],
// 	'OUTBOUND B2B': [

// 	],
// 	INVENTORY: [
		
// 	],
// 	OTHER: [
// 		'Ticketing',
// 		'Tagging',
// 		'Kitting',
// 	]
// };
const taskTypes = [
	'Ticketing',
	'Tagging',
	'Picking',
	'Kitting',
	'Recording',
	'Batching'
];
const taskStatus = [
	'PENDING',
	'IN PROGRESS',
	'END',
];


class WMSTasks extends Component {
  static contextType = DataContext;

  static propTypes = {
	classes: PropTypes.object.isRequired,
  };

  constructor(props) {
	super(props);

	this.temp_data = {
		keyword_array: [],
	};

	let defaultFilters = {
		status: {},
		department: {},
		type: {}
	};
	for (let status of taskStatus) defaultFilters['status'][status] = true;
	for (let dept of taskDepartments) defaultFilters['department'][dept] = true;
	
	if (Array.isArray(taskTypes))
	{
		// Task Type is a plain array
		for (let type of taskTypes) defaultFilters['type'][type] = true;
	} else {
		// Types are grouped under department
		for (let dept in taskTypes) 
		{
			for (let type of taskTypes[dept]) 
			{
				defaultFilters['type'][type] = true;
			}
		}
	}

	this.state = {
	  loading: false,
	  dialog: '',
	  // New Task Inputs
	  department: '', 
	  taskType: '',
	  totalUnit: '', // total units
	  totalTask: '', // number of tasks you want to  create
	  // Task List
	  taskList: {},
	  // Task multi selection
	  selectedTasks: {},
	  // Task filter 
	  displayFilters: defaultFilters,
	  // Editing Task
	  active_task: null,
	};

	document.title = "WMS Tasks";
  }

  componentDidMount() {
	this.loadTasks();
  }

  newTask = () => {
	const {department, taskType} = this.state;
	let totalUnit = this.state.totalUnit;
	let totalTask = this.state.totalTask;

	let warning = '';
	let err = '';
	if (!department) err += 'Department is required.\n';
	if (!taskType) err += 'Type is required.\n';
	if (!totalUnit) err += 'Total unit required.\n';
	else {
		totalUnit = parseInt(totalUnit);
		if (!totalUnit || totalUnit <= 0) err += 'Total unit should be a positive integer.\n';
	}
	if (!totalTask) err += 'Total task is required.\n';
	else {
		totalTask = parseInt(totalTask);
		if (!totalTask || totalTask <= 0) err += 'Total task should be a positive integer.\n';
	}

	if (totalUnit < totalTask) 
	{
		err += 'Total Units should >= Total Tasks';
	}
	if (totalUnit > 1000) 
	{
		warning += `You enterd ${totalUnit} Total Units, are you sure this is not a mistake and you still want to submit?\n`;
	}
	if (totalTask > 10) 
	{
		warning += `You enterd ${totalTask} Total Tasks, are you sure this is not a mistake and you still want to submit?\n`;
	}
	
	if (err) {
	  this.context.alert(err);
	  return;
	}

	if (warning) {
		this.context.confirm(warning, this.newTaskAjax.bind(this, {
			department,
			type: taskType,
			totalUnit, 
			totalTask,
		}));
		return;
	} else {
		this.newTaskAjax({
			department,
			type: taskType,
			totalUnit, 
			totalTask,
		});
	}

  }
  newTaskAjax = (req_data) => {
	let req = axios({
		method: 'post',
		url: `${utils.getBaseUrl('customer')}/newTask`,
		headers: {
		  token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
		},
		data: req_data,
	  });
  
	  this.setState({loading: true});
	  req.then(this.newTaskSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newTaskSuccess = (resp) => {
	this.setState({loading: false});
	if (resp.data.Error) {
	  this.context.alert(resp.data.Error);
	  return;
	}
	// reset page
	if (resp.data) window.location.reload();
	else {
	  this.context.alert("New task failed.");
	}
  }
  loadTasks = () => {
	let req = axios({
	  method: 'get',
	  url: `${utils.getBaseUrl('customer')}/taskList`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	});

	this.setState({loading: true});
	req.then(this.loadTasksSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadTasksSuccess = (resp) => {
	this.setState({loading: false});
	if (resp.data.Error) {
	  this.context.alert(resp.data.Error);
	  return;
	}

	let tasks = {};
	let keywords = [];
	for (let task of resp.data)
	{
		task.task_id = task.id;
		tasks[task.task_id] = task;
		keywords.push(task.task_id);
	}
	this.temp_data.keyword_array = keywords;
	this.setState({taskList: tasks});
	// this.setState({taskList: resp.data.task_list});
  }
  endTask = (finished_units) => {
	let task = Object.assign({}, this.state.active_task);
	task.units = finished_units;
	task.status = "END";

	let req = axios({
	  method: 'post',
	  url: `${utils.getBaseUrl('customer')}/updateTask`,
	  headers: {
	    token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	  data: {
		task_id: task.task_id,
		action: 'end',
		task: task
	  }
	});

	this.setState({loading: true});
	req.then(this.endTaskSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetSendListASNOnce}));
  }
  endTaskSuccess = (resp) => {
	this.setState({loading: false});
	if (resp.data.Error) {
	  this.context.alert(resp.data.Error);
	  return;
	}

	if (!resp.data || resp.data.length <= 0) 
	{
		this.context.alert("Error, can't load updated task.");
		return;
	}
	let updated_task = resp.data[0];
	updated_task.task_id = updated_task.id;

	let task_list =  Object.assign({}, this.state.taskList);
	task_list[updated_task.task_id] = updated_task;
	this.setState({dialog: '', taskList: task_list});
  }
  updateTask = (api_action, new_task) => {
	let old_task = Object.assign({}, this.state.active_task);
	let updated_task = Object.assign(old_task, new_task);
	updated_task.status = "IN PROGRESS";

	let req = axios({
	  method: 'post',
	  url: `${utils.getBaseUrl('customer')}/updateTask`,
	  headers: {
	    token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	  data: {
		task_id: old_task.task_id,
		action: api_action,
		task: updated_task
	  }
	});

	this.setState({loading: true});
	req.then(this.updateTaskSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetSendListASNOnce}));
  }
  updateTaskSuccess = (resp) => {
	this.setState({loading: false});
	if (resp.data.Error) {
	  this.context.alert(resp.data.Error);
	  return;
	}

	if (!resp.data || resp.data.length <= 0) 
	{
		this.context.alert("Error, can't load updated task.");
		return;
	}
	let updated_task = resp.data[0];
	updated_task.task_id = updated_task.id;

	let task_list = Object.assign({}, this.state.taskList);
	task_list[updated_task.task_id] = updated_task;
	this.setState({dialog: '', taskList: task_list});
  }
  deleteTask = () => {
	let keyword_array = [];
    for (let index in this.state.selectedTasks) {
      keyword_array.push(this.state.selectedTasks[index]['task_id']);
    }

	let req = axios({
		method: 'delete',
		url: `${utils.getBaseUrl('customer')}/deleteTask`,
		headers: {
		  token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
		},
		data: {
		  task_id: keyword_array,
		}
	  });
  
	  this.setState({loading: true});
	  req.then(this.deleteTaskSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetSendListASNOnce}));
  }
  deleteTaskSuccess = (resp) => {
	this.setState({loading: false});
	if (resp.data.Error) {
	  this.context.alert(resp.data.Error);
	  return;
	}

	window.location.reload();
  }

  applyFilter = (newVal, key) => {
    let newDisplayFilter = Object.assign({}, this.state.displayFilters);
    newDisplayFilter[key] = newVal;
	this.setState({displayFilters: newDisplayFilter, selectedTasks: {}});
  }
  getFilteredTasks = (rows, displayFilters) => {
    if (!displayFilters) displayFilters = this.state.displayFilters;

    return rows.filter((elem)=>{
      return displayFilters['status'][elem['status']] !== false && displayFilters['type'][elem['type']] !== false && displayFilters['department'][elem['department']] !== false;
    });
  }

  renderTaskTable = () => {
	const {classes} = this.props;
	const { taskList, selectedTasks, displayFilters} = this.state;

	let rows = [];
	for (let task_id of this.temp_data.keyword_array)
	{
		rows.push(taskList[task_id]);
	}

	let filtered_rows = this.getFilteredTasks(rows);

	let columns = [
	  {
		key: 'task_id',
		label: 'Task',
		searchable: true,
		contentNoWrap: true,
		render: (val, key, row, index)=>{
		    switch (row.status) 
		    {
		        case 'PENDING':
		            return (
		                <Button onClick={()=>{
		                    this.setState({
								dialog: 'start',
								active_task: row,
							});
		                }}>{val}</Button>
		            );
		            break;
				// No edit for now
		        case 'IN PROGRESS':
		            return (
		                <Button onClick={()=>{
							this.setState({
								dialog: 'end',
								active_task: row,
							});
		                }}>{val}</Button>
		            );
		            break;
		        // case 'END':
		        //     return (
		        //         <Button onClick={()=>{
		        //             if (row.packingslip_url) {
		        //               let w = window.open(row.packingslip_url);
		        //               if (!w) {this.context.alert('Please allow pop up window.');}
		        //             } else this.printTask(val); // call backend to print packingslip
		        //         }}>{val}</Button>
		        //     );
		        //     break;
		        default:
		            return val;
		    }
		},
	  },
	  {
		key: 'status',
		label: 'Status',
		// searchable: true,
		contentNoWrap: true,
		width: 'auto'
	  },
	  {
		key: 'department',
		label: 'Department',
		contentNoWrap: true,
		// searchable: true,
		width: 'auto'
	  },
	  {
		key: 'type',
		label: 'Type',
		// searchable: true,
		contentNoWrap: true,
		width: 'auto'
	  },
	  {
		key: 'units',
		label: 'Units',
	  },
	  {
		key: 'user_id',
		label: 'User',
		searchable: true,
		contentNoWrap: true,
	  },
	  {
		key: 'uph',
		label: 'UPH',
		contentNoWrap: true,
	  },
	  {
		key: 'start_dt',
		label: 'Start Date',
		contentNoWrap: true,
		render: utils.localizeDate
	  },
	  {
		key: 'end_dt',
		label: 'End Date', 
		contentNoWrap: true,
		render: utils.localizeDate
	  },
	  {
		key: 'created_dt',
		label: 'Create Date',
		contentNoWrap: true,
		render: utils.localizeDate
	  },
	];

	let rowSettings = {
	  rowProps: {
		hover: false
	  },
	  classNameOnDisplay: (task, displayProps) => {
		let color = '';
		switch(task.status) {
			case 'PENDING':
				color = 'light-red';
				break;
			case 'IN PROGRESS':
				color = 'yellow';
				break;
			case 'END':
				color = 'green';
				break; 
		}
		
		if (!displayProps) displayProps = {className: ''};
		let classes = displayProps.className || '';
		classes = classes.split(' ');
		classes.push(color);
		displayProps.className = classes.join(' ');
		return displayProps;
	  }
	};

	let buttons = [];
	buttons.push({label: 'Delete Task', onClick: ()=>{
		this.setState({ actionsAnchor: null});
		if (_.isEmpty(selectedTasks)) {
		  this.context.alert('No task selected.');
		  return;
		}

		this.context.confirm("Are you sure to Delete selected tasks (note that tasks under END status will not be deleted)?", this.deleteTask);
	  }
	});

	// filter options
	let statusOptions = [];
	for (let filterKey in displayFilters['status']) {
		statusOptions.push({selected: displayFilters['status'][filterKey], value: filterKey, label: filterKey});
	}
	let departmentOptions = [];
	for (let filterKey in displayFilters['department']) {
		departmentOptions.push({selected: displayFilters['department'][filterKey], value: filterKey, label: filterKey});
	}
	let typeOptions = [];
	for (let filterKey in displayFilters['type']) {
		typeOptions.push({selected: displayFilters['type'][filterKey], value: filterKey, label: filterKey});
	}

	return (
	  <GridItem xs={12} sm={12} md={12}>
		<CardContainer>
			<div>
				<ButtonGroup>
				<MultiSelect
					title="Status"
					listItems={statusOptions}
					handleSubmit={(val)=>{this.applyFilter(val, 'status')}}
				/>

				<MultiSelect
					title="Department"
					listItems={departmentOptions}
					handleSubmit={(val)=>{this.applyFilter(val, 'department')}}
				/>

				<MultiSelect
					title="Type"
					listItems={typeOptions}
					handleSubmit={(val)=>{this.applyFilter(val, 'type')}}
				/>
				</ButtonGroup>		
			</div>

			<br/>
		  
		  <div>
			<ButtonGroup>
				{buttons.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
			</ButtonGroup>
		  </div>

		  
		  <NewDataTable
			rows={filtered_rows}
			searchable
			rowsPerPage={100}
			rowSettings={rowSettings}
			rowSelection={{
				checkboxColor: 'primary',
				selected: selectedTasks, // since ajax table will not have sort by, data array index will always work for selection
				handleSelectedChange: (newSelected) => (this.setState({selectedTasks: newSelected}))
			}}
			columns={columns}
		  />
		</CardContainer>
	  </GridItem>
	);
  }
  renderDialog = () => {
	switch (this.state.dialog) {
		case 'start':
			return (
				<EditTask
					action="start"
					task={this.state.active_task}
					handleSubmit={this.updateTask}
					handleClose={()=>{this.setState({dialog: '', active_task: null})}}
			  />
			);
		case 'end':
			return (
				<EndTask
					task={this.state.active_task}
					handleSubmit={this.endTask}
					handleClose={()=>{this.setState({dialog: '', active_task: null})}}
			  />
			);
	}
	return null;
  }

  render() {
	const {classes} = this.props; 
	const {loading, taskType, department, totalUnit, totalTask} = this.state;

	let type_options = [];
	if (Array.isArray(taskTypes))
	{
		// Task Type is a plain array
		type_options = taskTypes;
	} else {
		// Types are grouped under department
		if (taskTypes[department]) {
			type_options = taskTypes[department];
		}
	}

	return (
	  <Grid container spacing={2}>
		{loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

		<GridItem xs={12} sm={6} md={6}>
		  <CardContainer style={{minHeight: '266px'}}>
			<FormControl fullWidth required className={classes.customInput}>
			  <InputLabel shrink>Department</InputLabel>
			  <Select
				value={department}
				onChange={(e) => { 
					// If using types under dept turn this on
					// if (e.target.value !== this.state.department)
					// {
					// 	this.setState({ taskType: '' });
					// }
					this.setState({ department: e.target.value }) }
				}
			  >
				{taskDepartments.map((elem)=><MenuItem key={elem} value={elem}>{elem}</MenuItem>)}
			  </Select>
			</FormControl>

			<FormControl fullWidth required className={classes.customInput}>
			  <InputLabel shrink>Type</InputLabel>
			  <Select
				value={taskType}
				disabled={type_options.length == 0}
				onChange={(e)=>{this.setState({taskType: e.target.value})}}
			  >
				{type_options.map((elem)=><MenuItem key={elem} value={elem}>{elem}</MenuItem>)}
			  </Select>
			</FormControl>
			
			<CustomInput
			  labelText='Total Unit'
			  labelProps={{shrink: true}}
			  formControlProps={{
				fullWidth: true,
				required: true,
				className: classes.customInput
			  }}
			  inputProps={{
				type: 'number',
				inputProps: {
				  min: 1,
				  step: 1,
				},
				value: totalUnit,
				onChange: (e)=>{this.setState({totalUnit: e.target.value})},
			  }}
			/> 
			
			<CustomInput
			  labelText='Total Task'
			  labelProps={{shrink: true}}
			  formControlProps={{
				fullWidth: true,
				required: true,
				className: classes.customInput,
				style: {paddingBottom: '1px'}
			  }}
			  inputProps={{
				type: 'number',
				inputProps:{
				  min: 1,
				  step: 1
				},
				value: totalTask,
				onChange: (e)=>{this.setState({totalTask: e.target.value})},
			  }}
			/>

			<div className={classes.toolBar} style={{marginTop: '.5rem'}}>
				<Button onClick={this.newTask}>Submit</Button>
			</div>
		  </CardContainer>
		</GridItem>

		{this.renderDialog()}
		{this.renderTaskTable()}
	  </Grid>
	);
  }
}
export default withStyles(styles)(WMSTasks);
