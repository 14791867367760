import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles} from '@material-ui/core'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  customInput: {
    margin: theme.spacing(0)
  },
}))

const productInputValidation = [
  {
    key: 'sku',
    label: 'SKU',
    type: 'string',
    required: true,
    length: 25
  },
  {
    key: 'upc',
    label: 'UPC',
    type: 'string',
    length: 25
  },
  {
    key: 'item_name',
    label: 'Item Name',
    type: 'string',
    required: true,
  },
  {
    key: 'retail_value',
    label: 'Retail Value',
    type: 'number',
    required: true,
    // allow 0 
    // positive: true,
  },
  {
    key: 'wholesale_value',
    label: 'Wholesale Value',
    type: 'number',

    // allow 0 
    // positive: true,
  },
  {
    key: 'category',
    label: 'Category',
    type: 'string',
    required: true,
  },
  // {
  //   key: 'min_unit',
  //   label: 'Min unit',
  //   type: 'number',
  //   // don't allow 0
  //   positive: true,
  // },
  // {
  //   key: 'max_unit',
  //   label: 'Max unit',
  //   type: 'number',
  //   // don't allow 0
  //   positive: true,
  // },
  {
    key: 'weight',
    label: 'Weight',
    type: 'number',
    required: true,
    // don't allow 0
    positive: true,
  },
];

export default function EditProduct(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {product: defaultProduct, handleClose, handleSubmit} = props;
  const [product,setProduct] = useState(defaultProduct);

  function onProductChange(key, newVal) {
    let newProduct = Object.assign({}, product);
    newProduct[key] = newVal;
    setProduct(newProduct);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="SKU"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
               disable: defaultProduct.item_id, // if default product has item_id, this is editing, enable sku input
               inputProps: {placeholder: "length <= 25, only number, letter, dash and underscore allowed"},
               onChange: (e)=>{onProductChange('sku', e.target.value)},
               value: product.sku || ""
            }}
          />
        </GridItem>
        
        <GridItem xs={12} sm={6} md={4}>
         <CustomInput
            labelText="UPC"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
               inputProps: {placeholder: "length <= 25, only number, letter, dash and underscore allowed"},
               onChange: (e)=>{onProductChange('upc', e.target.value)},
               value: product.upc || ""
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Item Name"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
               onChange: (e)=>{onProductChange('item_name', e.target.value)},
               value: product.item_name || ""
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Short Description"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
               onChange: (e)=>{onProductChange('short_description', e.target.value)},
               value: product.short_description || ""
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Retail Value"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Should be a non-negative number"},
              onChange: (e)=>{onProductChange('retail_value', e.target.value)},
              value: product.retail_value
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Wholesale Value"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Should be a non-negative number"},
              onChange: (e)=>{onProductChange('wholesale_value', e.target.value)},
              value: product.wholesale_value
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Cost"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Should be a non-negative number"},
              onChange: (e)=>{onProductChange('cost', e.target.value)},
              value: product.cost
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="LDP Cost"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Should be a non-negative number"},
              onChange: (e)=>{onProductChange('ldp_cost', e.target.value)},
              value: product.ldp_cost
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="HS Code"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Required by Customs when shipping international order"},
              onChange: (e)=>{onProductChange('hs_code', e.target.value)},
              value: product.hs_code || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Category"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('category', e.target.value)},
              value: product.category || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Subcategory"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('sub_category', e.target.value)},
              value: product.sub_category || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Collection"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('collection', e.target.value)},
              value: product.collection || ''
            }}
          />
        </GridItem>  

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Style"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('style', e.target.value)},
              value: product.style || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Size"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('size', e.target.value)},
              value: product.size || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Color"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('color', e.target.value)},
              value: product.color || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Material"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('material', e.target.value)},
              value: product.material || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Manufacturer"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('manufacturer', e.target.value)},
              value: product.manufacturer || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Country of Origin"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "2-digit ISO country code"},
              onChange: (e)=>{onProductChange('country_origin', e.target.value)},
              value: product.country_origin || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Image URL"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('image', e.target.value)},
              value: product.image || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Weight"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              inputProps: {placeholder: "Should be a non-negative number"},
              onChange: (e)=>{onProductChange('weight', e.target.value)},
              value: product.weight
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Length (Inch)"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('dim_length', e.target.value)},
              value: product.dim_length || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Width (Inch)"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('dim_width', e.target.value)},
              value: product.dim_width || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Height (Inch)"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('dim_height', e.target.value)},
              value: product.dim_height || ''
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Min Unit"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              type: 'number',
              inputProps:{
                min: 0,
                step: 1
              },
              inputProps: {placeholder: "Should be a non-negative integer"},
              onChange: (e)=>{onProductChange('min_unit', e.target.value)},
              value: product.min_unit
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Max Unit"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              type: 'number',
              inputProps:{
                min: 0,
                step: 1
              },
              inputProps: {placeholder: "Should be a non-negative integer"},
              onChange: (e)=>{onProductChange('max_unit', e.target.value)},
              value: product.max_unit
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Unit Case"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('unit_case', e.target.value)},
              value: product.unit_case
            }}
          />
        </GridItem>

        {/* <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Unit Case"
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              onChange: (e)=>{onProductChange('unit_case', e.target.value)},
              value: product.unit_case || ''
            }}
          />
        </GridItem> */}
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';
    if (!product.collection) product.collection = '';
    if (!product.country_origin) product.country_origin = '';
    if (!product.min_unit) product.min_unit = '';
    if (!product.max_unit) product.max_unit = '';

    for (let property of productInputValidation) {
      let inputVal = product[property.key];

      if (!inputVal && (inputVal !== 0)) {
        if (property.required) err += `${property.label} is required.\n`;
        continue;
      }

      switch (property.type) {
        case 'number':
          inputVal = parseFloat(inputVal);
          if (isNaN(inputVal)) {
            err += `${property.label} should be a number.\n`;
            continue;
          }
          if (property.positive){
            if (inputVal <= 0) err += `${property.label} should be greater than 0. \n`;
          } else if (inputVal < 0) {
            err += `${property.label} should be greater than or equal to 0. \n`;
          }
          break;
        case 'string':
        default:
          inputVal = inputVal.trim(); // trim string value
          if (property.length && inputVal.length > property.length) {
            err += `${property.label} is too long (more than ${property.length}). \n`;
          }
          break;
      }
    }

    if (err) dataContext.alert(err);
    else handleSubmit(product); // todo, decide if need to pass the formatted
  }

  let title = "Create Product";

  return (
    <BasicDialog
      fullWidth
      maxWidth='lg'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}