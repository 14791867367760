import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert';
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import moment from 'moment-timezone'
import {withTimer} from 'hoc'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoLabel: {
    marginTop: '0 !important'
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class B2CPacking extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    timer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      order_barcode: null,
      sku: null,
      supply: null,
      supply_box: null
    };

    this.time_count_interval = null;

    this.temp_data = {
      order_id: '',
      cartons: [],
      packing_finished: false,
      scanning_box: false,
      un_focus: false,
      sku_map: null,
      upc_map: null,
      barcode_map: {},
      table_keys: null,
      show_insert: false,
      supply_insert: [],
    };

    this.state = {
      loading: false,
      items: null,
      orderDetail: null,
      comment: [],
      supply_list: [],
      box_list: [],
    };

    document.title = "B2C Packing";
  }

  componentDidMount() {
    this.props.timer.start();
  }

  resetPage = () => {
    if (this.input_refs.order_barcode) {
      this.input_refs.order_barcode.value = '';
      this.input_refs.order_barcode.focus();
    }

    this.temp_data = {
      order_id: '',
      cartons: [],
      packing_finished: false,
      scanning_box: false,
      un_focus: false,
      sku_map: null,
      upc_map: null,
      table_keys: null,
      show_insert: false,
      supply_insert: [],
    };

    this.setState({
      loading: false,
      items: null,
      orderDetail: null,
      comment: [],
      supply_list: [],
      box_list: [],
    });
  }

  orderSearch = () => {
    let keyword = utils.formatString(this.input_refs.order_barcode.value);
    if (!keyword) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/b2cPacking/${keyword}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.temp_data.cartons = [];
    this.temp_data.packing_finished = false;
    this.temp_data.scanning_box = false;
    this.setState({
      loading: true,
      items: null,
      orderDetail: null,
      comment: [],
      supply_list: [],
      box_list: [],
    });
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    for (let item of data.order) {
      let id =item.item_id;
      if (!item.quantity_input) item.quantity_input = 0;
      if (!item.packed) item.packed = 0;
      items[id] = item;

      // Remove leading zeros for Brayola issues
      // let sku = item.sku ? item.sku.replace(/^0+/, '') : '';
      let sku = item.sku;
      let upc = item.upc;
      if (!skus[sku]) {
          skus[sku] = id;
      }
      if (!upcs[upc]) {
          upcs[upc] = id;
      }
      keys.push(id);
    }

    let order_cartons = [];
    for (let carton_id in data.carton) order_cartons.push(data.carton[carton_id].item);
    this.temp_data.order_id = data.orderDetail.order_id;
    this.temp_data.cartons = order_cartons;
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;
    this.temp_data.un_focus = false;
    this.temp_data.show_insert = data.turnon;
    this.temp_data.supply_insert = data.supply;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;


    this.setState({items: items, orderDetail: data.orderDetail, comment: data.comment});
  }
  orderBarcodeScan = _.debounce(this.orderSearch, 150);
  newCarton = () => {
    let err = utils.checkCustomerWarehouseChanged(this.context);
    if (err) {
      this.context.alert(err);
      return;
    }

    const {supply_list, box_list} = this.state;

    let new_carton = this.computeCarton();
    if (new_carton.length === 0) {
      this.context.alert("You need at least one item to create a new carton");
      return;
    }
    
    let items_object = {};
    for (let item of new_carton) items_object[item.item_id] = item; // convert carton item list to object
    let no_split = false;
    
    if (supply_list.length == 0 && box_list.length == 0) {
      this.context.alert('Supply is required.');
      return;
    }

    if (this.temp_data.packing_finished && this.temp_data.cartons.length == 0) no_split = true; // check if this order only has one carton, can't handle multi tab packing 
    
    let req_data = {
      finished: this.temp_data.packing_finished, 
      order_id: this.temp_data.order_id,
      supply: supply_list.map((elem)=>elem.sku).join(','), // todo might need change to pass an array 
      // supply_box: box_list.map((elem)=>elem.sku).join(','), // todo might need change to pass an array 
      // supply: supply_list.map((elem)=>elem.sku).concat(box_list.map((elem)=>elem.sku)).join(','),
      items: items_object,
      no_split,
    };

    this.context.confirm("Are you sure to create a new carton?", this.newCartonOnce.bind(this, new_carton, req_data));
  }
  computeCarton = () => {
    const {items} = this.state;
    let finished = true;

    let result_carton = [];
    for (let item_id of this.temp_data.table_keys) {
      let carton_item = Object.assign({}, items[item_id]);

      let new_qty = parseInt(carton_item.quantity_input) || 0;
      let packed = parseInt(carton_item.packed) || 0;

      carton_item.quantity_input = new_qty;
      if ((new_qty + packed) !== parseInt(carton_item.quantity)) finished = false; // at least one item didn't finish packing
      if (new_qty > 0) result_carton.push(carton_item);
    }
    this.temp_data.packing_finished = finished;

    return result_carton;
  }
  newCartonAjax = (new_carton, reqData) => {
    let keyword = utils.formatString(this.input_refs.order_barcode.value);
    if (!keyword) return;

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/b2cPacking`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: reqData
    });
    this.setState({loading: true});
    req.then(this.newCartonSuccess.bind(this, new_carton)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  newCartonOnce = _.once(this.newCartonAjax);
  resetSubmitOnce = () => {this.newCartonOnce = _.once(this.newCartonAjax);} // reset the submit picking once after the ajax call returns
  newCartonSuccess = (new_carton, resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    // packing error, shipping error.

    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    // if packing is finished and order is auto shipped, print shipping label
    if (!result.carton_id && !result.item_list) {
      switch (true) {
        case typeof result === "string":
          utils.printPage(result, true);
          break;
        case Array.isArray(result):
          result.forEach((elem, index) => {
            if (index == 0) {
              utils.printPage(elem, true);
            } else {
              utils.printPage(elem);
            }
          });
          break;
      }
      this.resetPage();
      // window.location.reload();
      return;
    }

    // print carton barcode
    this.printBarcode4by2(result.carton_id);
    let cartons = Array.from(this.temp_data.cartons);
    if (!cartons) cartons = [];
    new_carton = new_carton.map((elem)=>{elem.carton_id = result.carton_id; return elem;});
    cartons.push(new_carton);
    this.temp_data.cartons= cartons;
    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    let finished = true;
    for (let item of result.item_list) {
      let id = item.item_id;
      item.quantity_input = item.quantity_input || 0;
      item.packed = item.packed || 0;
      if (parseInt(item.packed) !== parseInt(item.quantity)) finished = false;
      items[id] = item;
      let sku = item.sku;
      let upc = item.upc;
      if (!skus[sku]) skus[sku] = id;
      if (!upcs[upc]) upcs[upc] = id;
      keys.push(id);
    }
   
    // packing is finished, reload page
    if (finished) { 
      this.context.snackDisplay("Packing Finished.");
      // window.location.reload();
      this.resetPage();
    } else {
      this.temp_data.sku_map = skus;
      this.temp_data.upc_map = upcs;
      this.temp_data.table_keys = keys;
      this.setState({items, supply_list: [], box_list: [],});
      this.temp_data.scanning_box = false;
      this.input_refs.sku.value = '';
      if (this.input_refs.sku) this.input_refs.sku.focus();
    }
  }
  printBarcode4by2 = (content) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/barcode4by2/${content}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.printBarcode4by2Success).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  printBarcode4by2Success = (resp) => {
    this.setState({loading: false});
    // packing error, shipping error.
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let print_label = resp.data;
    switch (true) {
      case typeof print_label === "string":
        utils.printPage(print_label, true);
        break;
      case Array.isArray(print_label):
        print_label.forEach((elem, index) => {
          if (index === 0) {
            utils.printPage(elem, true);
          } else {
            utils.printPage(elem);
          }
        });
        break;
    }
  }
  deleteCarton = (carton, index) => {
    let carton_id = carton[0]['carton_id'];
    // if index is incorrect, need to update it
    if (this.temp_data.cartons[index]['carton_id'] !== carton_id) {
      for (let i = 0; i < this.temp_data.cartons.length; i++) {
        if (this.temp_data.cartons[i]['carton_id'] === carton_id) {
          index = i;
          break;
        }
      }
    }

    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/b2bPacking/${carton_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.deleteCartonSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }  
  deleteCartonSuccess = (delete_index, resp) => {
    this.setState({loading: false});
    // packing error, shipping error.
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.temp_data.cartons.splice(delete_index, 1);
    let result = resp.data;

    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    let finished = true;
    for (let item of result) {
      let id = item.item_id;
      item.quantity_input = item.quantity_input || 0;
      item.packed = item.packed || 0;
      if (parseInt(item.packed) !== parseInt(item.quantity)) finished = false;
      items[id] = item;
      let sku = item.sku;
      let upc = item.upc;
      if (!skus[sku]) skus[sku] = id;
      if (!upcs[upc]) upcs[upc] = id;
      keys.push(id);
    }

    if (finished) {
      this.context.snackDisplay("Packing Finished.");
      // window.location.reload();
      this.resetPage();
    } else {
      this.temp_data.sku_map = skus;
      this.temp_data.upc_map = upcs;
      this.temp_data.table_keys = keys;
      this.setState({ items: items });
      if (this.input_refs.sku) this.input_refs.sku.focus();
    }
  }

  addSupply = () => {
    let supply = utils.formatString(this.input_refs.supply.value);
    if (!supply) return;
    let newSupplies = Array.from(this.state.supply_list);
    newSupplies.push({sku: supply});
    this.setState({supply_list: newSupplies});
    this.input_refs.supply.value = '';
    this.input_refs.supply.focus();
    // this.temp_data.scanning_box = false;
    this.temp_data.scanning_box = true;
  }
  supplyScan = _.debounce(this.addSupply, 150)
  addSupplyBox = () => {
    let supply = utils.formatString(this.input_refs.supply_box.value);
    if (!supply) return;
    let newSupplies = Array.from(this.state.box_list);
    newSupplies.push({sku: supply});
    this.setState({box_list: newSupplies});
    this.input_refs.supply_box.value = '';
    this.input_refs.supply_box.focus();
    this.temp_data.scanning_box = true;
  }
  supplyBoxScan = _.debounce(this.addSupplyBox, 150)
  packSku = () => {
    let scanned_val = this.input_refs.sku?.value;
    if (!scanned_val) return;

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    this.temp_data.scanning_box = false;

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
    else if (this.temp_data.upc_map[scanned_val]) matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
    else {
      this.context.alert(`The scanned item ${scanned_val} is not in this Order.`);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }  
      return;
    }

    let items = Object.assign({}, this.state.items);
    let old_qty = parseInt(items[matched_id].quantity_input) || 0;
    if ((1 + old_qty) > (parseInt(items[matched_id].quantity) - parseInt(items[matched_id].packed))) {
      this.context.alert("Item has finished packing, please scan another one.");
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }
      return;
    }
    items[matched_id].quantity_input = 1 + old_qty;
    let newKeys = [];
    newKeys.push(matched_id);
    for (let id of this.temp_data.table_keys) {
      if (id != matched_id) {
        newKeys.push(id);
      }
    }
    this.temp_data.table_keys = newKeys;
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }

    if (items[matched_id].sku == 'H-01' && (parseInt(localStorage.getItem('customer_id')) === 131 || parseInt(localStorage.getItem('customer_id')) === 190)) this.hazmatAlert();
    // before updating items, check packing finished
    this.detectFinished(items);

    this.setState({ items: items });
  }
  handleSKUScan = _.debounce(this.packSku, 150)
  hazmatAlert = _.debounce(() => {
    this.context.alert('Please pack h-01 into one box and use Hazmat stickers.\n Empaque h-01 en una caja y use calcomanías de materiales peligrosos (Hazmat stickers).');
  }, 1000);
  detectFinished = (items) => {
    for (let item_id in items) {
      let item = items[item_id];
      if ((parseInt(item.quantity_input) + parseInt(item.packed)) < parseInt(item.quantity)) {
        this.temp_data.packing_finished = false;
        this.temp_data.scanning_box = false;
        return false;
      }
    }

    // scroll to top
    utils.scrollToTop();
    this.temp_data.packing_finished = true;
    this.temp_data.scanning_box = true;
    return true;
  }
 
  renderOrderDetailTable = () => {
    const { orderDetail } = this.state;
    if (!orderDetail) return null;

    return (
      <div style={{marginTop: '.5rem', width: '100%'}}>
        <KeyValueTable
          style={{marginTop: '.5rem'}}
          dataObj={orderDetail}
          rowHeight="auto"
          propertyList={[
            {
              key: 'fullname',
              label: 'Fullname',
            },
            {
              key: 'company',
              label: 'Company',
            },
            {
              key: 'method',
              label: 'Shipping Method',
              render: utils.convertShippingMethod
            },
            {
              key: 'country',
              label: 'Country',
            },
            {
              key: 'slipnotes',
              label: 'Slip Notes',
            },
          ]}
        />
      </div>  
    );
  }
  renderCommentTable = () => {
    const { items, comment } = this.state;
    if (!items || items.length == 0) return null;

    let colSettings = [
      {
        key: 'username',
        label: 'Username',
      },
      {
        key: 'fullname',
        label: 'Fullname',
      },
      {
        key: 'comment',
        label: 'Comment',
        render: (val, key, row) => {
          let content = val ? val : '';
          if (content.includes("https://")) {
            let btn_text = "See PDF";
            if (content.includes(".pdf")) 
            {
              btn_text = "See PDF";
            } else 
            {
              btn_text = "See Image";
            }
            let file_name = content.split("import/");
            if (file_name.length > 1)
            {
              btn_text = utils.removeFilePrefix(file_name[1]);
            } 
            return (<Button onClick={()=>{
                let newTab = window.open(content);
                if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
            }}>{btn_text}</Button>);
          }
          return content;
        }
      },
      {
        key: 'date',
        label: 'Date/Time',
        render: utils.localizeDate,
      }
    ];

    return (
      <GridItem xs={12}>
        <div style={{marginTop: '1rem'}} className={this.props.classes.root}>
          <NewDataTable
            rows={comment}
            columns={colSettings}
            noPagination
            withPaper={false}
          />
        </div>
      </GridItem>
    );
  }
  renderSupplyTable = () => {
    const {classes} = this.props;
    const {supply_list, box_list, items} = this.state;
    if (!items || !this.temp_data.order_id) return;

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      }, {
        key: 'delete',
        label: 'Delete',
        render: (sku, key, product, index)=>{
          return (
            <span>
             <IconButton color="secondary" size="small" onClick={()=>{
               let newList = Array.from(this.state.supply_list);
               newList.splice(index, 1);
               this.setState({supply_list: newList});
               }} variant="fab" aria-label="Delete">
                <DeleteForever/>
              </IconButton>
            </span>
          );
        },
      }
    ];

    return (
      <GridItem xs={12} sm={6}>
        <CardContainer>
          <div className={this.props.classes.root}>
            {
              this.temp_data.show_insert && 
              <React.Fragment>
                <Typography variant="h6">
                  Marketing Insert <br/> (Please pack the following supplies into box)
                </Typography>
                <NewDataTable
                  rows={this.temp_data.supply_insert}
                  columns={[
                    {
                      key: 'sku',
                      label: 'SKU',
                      contentNoWrap: true,
                    }, {
                      key: 'quantity',
                      label: 'Quantity',
                    }
                  ]}
                  rowSettings={{
                    rowProps: {
                      hover: false
                    },
                    classNameOnDisplay: (batch, displayProps) => {
                      let color = 'green';
                      
                      if (!displayProps) displayProps = {className: ''};
                      let classes = displayProps.className || '';
                      classes = classes.split(' ');
                      classes.push(color);
                      displayProps.className = classes.join(' ');
                      return displayProps;
                    }
                  }}
                  noPagination
                />
              </React.Fragment>
            }

            <div style={{marginBottom: '1rem'}} className={classes.title} >
              <Typography variant="h6">
                Scanned Supplies
              </Typography>

              <CustomInput
                labelText='Supply'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: "Scan to add supply",
                  onKeyUp: this.supplyScan,
                  onFocus: ()=>{this.temp_data.un_focus = false; this.temp_data.scanning_box = true; },
                  onBlur: ()=>{this.temp_data.un_focus = true;},
                  disabled: this.state.loading,
                  inputRef: elem => this.input_refs.supply = elem
                }}
              />
            </div>

            <NewDataTable
              rows={supply_list}
              columns={columns}
              noPagination
            />
          </div>

          {/* <div style={{marginTop: '1rem'}} className={this.props.classes.root}>
            <div style={{marginBottom: '1rem'}} className={classes.title} >
              <Typography variant="h6">
                Boxes & Poly Mailers
              </Typography>

              <CustomInput
                labelText='Boxes & Poly Mailers'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: "Scan to add supply",
                  onKeyUp: this.supplyBoxScan,
                  disabled: this.state.loading,
                  inputRef: elem => this.input_refs.supply_box = elem
                }}
              />
            </div>

            <NewDataTable
              rows={box_list}
              columns={box_columns}
              noPagination
            />
          </div> */}
        </CardContainer>
      </GridItem>
    );
  }
  renderOrderItemTable = () => {
    const { classes } = this.props;
    const { items } = this.state;
    if (!items) return null;
    let rows = [];
    for (let id of this.temp_data.table_keys) rows.push(items[id]);

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
        }
      },
      {
        key: 'name',
        label: 'Description',
        // render: (val, key, row) => {
        //   return (<div style={{ maxHeight: "80px", overflow: "auto" }}>{val}</div>);
        // }
      },
      {
        key: 'quantity',
        label: 'Quantity',
      },
      // {
      //   key: 'location',
      //   label: 'Location',
      //   contentNoWrap: true
      // },
      {
        key: 'lot',
        label: 'Lot',
      },
      {
        key: 'packed',
        label: 'Packed',
      },
      {
        key: 'quantity_input',
        label: 'Scanned',
        render: (val, key, row, index) => {
          return (
            <CustomInput
              labelText=''
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: val,
                type: 'number',
                disabled: true,
                className: classes.customInputNoLabel,
                onChange: (e) => {
                  let val = e.target.value;
                  let newItems = Object.assign({}, this.state.items);
                  let newItem = Object.assign({}, newItems[row.item_id]);
                  if (val < 0) val = 0;
                  let needed = parseInt(newItem.quantity) ? parseInt(newItem.quantity) : 0;
                  let packed = parseInt(newItem.packed) ? parseInt(newItem.packed) : 0;

                  if (parseInt(val) > (needed - packed)) {
                    // alert("Order only need " + needed + ' of this items, please check your input');
                    if ((needed - packed) === 0) {
                      this.context.alert("This item has finished packing");
                    } else {
                      this.context.alert("Only " + (needed - packed) + ' more of this item need to be packed');
                    }
                    return;
                  }

                  newItem['quantity_input'] = val;
                  newItems[row.item_id] = newItem;

                  this.detectFinished(newItems);

                  this.setState({ items: newItems });

                  if (newItem.sku == 'H-01' && (parseInt(localStorage.getItem('customer_id')) === 131 || parseInt(localStorage.getItem('customer_id')) === 190)) this.hazmatAlert();
                }
              }}
            />
          );
        },
      },
      {
        key: 'discrepancy',
        label: 'Discrepancy',
        render: (val, key, row, index) => {
          let scanned = parseInt(row.quantity_input) ? parseInt(row.quantity_input) : 0;
          let packed = parseInt(row.packed) ? parseInt(row.packed) : 0;
          let quantity = parseInt(row.quantity) ? parseInt(row.quantity) : 0;
          return (scanned + packed - quantity);
        },
      },
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (item, displayProps) => {
        let color = '';

        let need = parseInt(item.quantity);
        let input = parseInt(item.quantity_input);
        let packed = parseInt(item.packed);

        switch (true) {
          case need === (input + packed):
            color = 'green';
            break;
          case !input:
            color = '';
            break;
          default:
            if (need < (input + packed)) color = 'yellow';
            else color = 'light-red';
        }
        
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    _.delay(()=>{
      if (!this.temp_data.un_focus) {
        if (this.temp_data.scanning_box) {
          if (this.input_refs.supply) this.input_refs.supply.focus();
        } else {
          if (this.input_refs.sku) this.input_refs.sku.focus();
        }
      }
    }, 0);

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div style={{marginBottom: '1rem'}} className={classes.title} >
            <Typography variant="h6">
              Order Items
            </Typography>

            <CustomInput
              labelText='SKU/UPC'
              formControlProps={{
                fullWidth: false,
                style: { marginRight: '1rem' },
                className: classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                defaultValue: '',
                onKeyUp: this.handleSKUScan,
                onFocus: ()=>{this.temp_data.un_focus = false; this.temp_data.scanning_box = false; },
                onBlur: ()=>{this.temp_data.un_focus = true;},
                onPaste: (e)=>{
                  if (!utils.checkAllowCopy()) e.preventDefault();
                },
                inputRef: elem => this.input_refs.sku = elem
              }}
            />

            <Button style={{marginTop: '.5rem'}} onClick={this.newCarton}>{(this.temp_data.packing_finished ? 'Finish Packing' : 'New Carton')}</Button>
          </div>

          <NewDataTable
            rows={rows}
            noPagination
            rowSettings={rowSettings}
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>  
      </GridItem>
    );
  }
  renderCartonTable= () => {
    const { items } = this.state;
    const { cartons } = this.temp_data;
    if (!items || !cartons || cartons.length == 0) return null;

    let columns = [
      {
        key: 'carton_id',
        label: 'Barcode',
      },
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      }, {
        key: 'upc',
        label: 'UPC',
      }, {
        key: 'name',
        label: 'Description',
        width: 'auto',
        // wrap
      }, {
        key: 'quantity_input',
        label: 'Quantity',
      }
    ];

    let carton_tables = cartons.map((carton_detail, index)=>{
      return (
        <GridItem key={index} xs={12}>
          <CardContainer>
            <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Carton {(index + 1)}</Typography>
            <div><Button onClick={()=>{
               this.context.confirm("Are you sure to delete this carton?", this.deleteCarton.bind(this, carton_detail, index));
            }}>Delete Carton</Button></div>
            <NewDataTable
              rows={carton_detail}
              noPagination
              // rowHeight={50} 
              columns={columns}
            />
          </CardContainer>  
        </GridItem>
      );
    });
    
    return (
      <React.Fragment>
        {carton_tables}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { items, dialog } = this.state;
    if (!items && !this.temp_data.un_focus) {
      _.delay(()=>{
        if (this.input_refs.order_barcode) this.input_refs.order_barcode.focus();
      }, 0);
    }
    
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <MuiAlert severity="info">Total work time on this page: {new Date(this.props.timer.get() * 1000).toISOString().substring(11, 19)}</MuiAlert>

            <br/>

            <CustomInput
              labelText='Order Barcode'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onKeyUp: this.orderBarcodeScan,
                defaultValue: '',
                onFocus: ()=>{this.temp_data.un_focus = false;},
                onBlur: ()=>{this.temp_data.un_focus = true;},
                inputRef: elem => this.input_refs.order_barcode = elem
              }}
            />

            {this.renderOrderDetailTable()}
            {this.renderCommentTable()}
          </CardContainer>
        </GridItem>

        {this.renderSupplyTable()}
        
        {this.renderOrderItemTable()}
        {this.renderCartonTable()}
      </Grid>
    );
  }
}
export default withTimer(withStyles(styles)(B2CPacking));
