import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from 'prop-types'
import axios from 'axios';
import utils from 'utils/utils'
import _ from 'lodash'
import { withStyles, Grid, Container, Avatar, Paper, Link, CssBaseline, TextField, Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import {
  Button,
  GridItem,
  CustomInput,
  LoadingBar,
  PopupAlert
} from "components";
import DataContext from 'context/Data'
import logo from "../../assets/img/logo.png";

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%"
  },
  errorMessage: {
    padding: theme.spacing(1, 0)
  },
  resendLink: {
    cursor: "pointer",
  },
  disabledResendLink: {
    cursor: "not-allowed",
    color: "gray"
  },
  inputLabel: {
    fontWeight: "bold"
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://boxzooka.com">
        Boxzooka
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export class Login extends Component {
  static contextType = DataContext;

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      feedback_msg: "",
      feedback_type : "",
      usernameError: "",
      pwdError: "",
      loading: false,
      // related to email verification
      verification_required: false,
      verification_code: "",
      resend_countdown: 60,
      countdown_interval: null,
      // Pop up alert
      message_type: "",
      message_content: "",
    };

    document.title = "Login";
  }

  render() {
    const { classes } = this.props;
    const { feedback_msg, feedback_type, loading, username, password, verification_required, verification_code, resend_countdown } = this.state;

    let messageField = null;
    if (feedback_msg) {
      messageField = (
        <div style={{width: "100%", padding: "2rem 0 1rem 0"}}>
          <Alert
            variant="filled"
            severity={feedback_type ? feedback_type : "error"}
          >
            {feedback_msg}
          </Alert>
        </div>
      );
    }

    let form_content = ( <form className={classes.form} onSubmit={this.login}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        InputLabelProps={{className: classes.inputLabel}}
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        onChange={(e)=>{this.setState({username: e.target.value})}}
        value={username}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        InputLabelProps={{className: classes.inputLabel}}
        fullWidth
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
        onChange={(e)=>{this.setState({password: e.target.value})}}
        value={password}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={this.state.loading}
      >
        Sign In
      </Button>
      <Grid container spacing={2}>
        <Grid item xs>
          <Link href="/resetPassword" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </form>);

    if (verification_required) {
      let btn_resend = (
        <Link onClick={this.resendEmail} className={classes.resendLink} variant="body2">
          (Didn't receive the email? Click to resend.)
        </Link>
      );
      if (resend_countdown > 0) {
        btn_resend = (
          <Link className={classes.disabledResendLink} variant="body2">
            ({resend_countdown} seconds to resend email)
          </Link>
        );
      }

      form_content = ( <form className={classes.form} onSubmit={this.loginViaCode}>
        <Typography>
          An access code has been sent to your email, enter it below to create a new password.
          {/*Todo when resend access code api is ready, move this back*/}
          {/*{btn_resend}*/}
        </Typography>

        {/*<TextField*/}
        {/*  variant="outlined"*/}
        {/*  margin="normal"*/}
        {/*  required*/}
        {/*  fullWidth*/}
        {/*  label="Username"*/}
        {/*InputLabelProps={{className: classes.inputLabel}}*/}
        {/*  name="username"*/}
        {/*  autoComplete="username"*/}
        {/*  autoFocus*/}
        {/*  onChange={(e)=>{this.setState({username: e.target.value})}}*/}
        {/*  value={username}*/}
        {/*/>*/}

        <CustomInput
          labelText="Access Code"
          formControlProps={{
            fullWidth: true,
            required: true,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{this.setState({verification_code: e.target.value})},
            value: verification_code,
            // type: 'password'
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={this.state.loading}
        >
          Submit Code
        </Button>

        <Grid container spacing={2}>
          <Grid item xs>
            <Link href="/login" variant="body2">
              Login with another username?
            </Link>
          </Grid>
        </Grid>
      </form>);
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {loading ? <LoadingBar/> : null}

        <div className={classes.paper}>
          {<img className='profile-img-card' style={{width: "95%"}} src={logo}/>}
  
          <Typography component="h1" variant="h5" style={{height: "13.06px"}}>
            {verification_required ? "Verifying code for: " + username : ""}
          </Typography>

          {messageField}

          {form_content}

        </div>

        <Box mt={8}>
          <Copyright />
        </Box>

        {this.renderAlert()}
      </Container>
    );
  }

  componentWillUnmount() {
    clearInterval(this.state.countdown_interval);
  }

  // Login
  login = (e) => {
    e.preventDefault();
    const { username, password } = this.state;

    this.loginOnce({
      username,
      password,
    });
  }
  loginAjax = (data) => {
    let req = axios.post(
      `${utils.getBaseUrl('customer')}/login`,
      data
    );

    req.then(this.loginSuccess).catch(this.loginError);
    this.setState({loading: true, feedback_msg: ''});
  }
  loginOnce = _.once(this.loginAjax);
  loginSuccess = (resp) => {
    this.loginOnce = _.once(this.loginAjax);
    this.setState({loading: false});

    if (resp.data.Error) {
      let msg = resp.data.Error;
      this.setState({ feedback_msg: msg });
      return;
    }

    // Todo need to check if IP detection passed, if not show verification
    if (resp.data.check_ip) {
      if (this.state.countdown_interval) clearInterval(this.state.countdown_interval);
      let countdown_interval = setInterval(()=>{
        if (this.state.resend_countdown > 0) {
          this.setState({resend_countdown: this.state.resend_countdown-1});
        }
      }, 1000);

      this.setState({
        feedback_msg: "You are logging in from a different IP, access code needed, please check your email",
        feedback_type: "error",
        verification_required: true,
        resend_countdown: 60,
        countdown_interval
      });
      return;
    }

    let account = resp.data;
    let default_warehouse = "";
    let warehouse_detail = {};
    if (Array.isArray(account.warehouse_detail)) {
      for (let warehouse of account.warehouse_detail) {
        warehouse_detail[warehouse.warehouse_id] = warehouse.name;
      }
    } else {
      warehouse_detail = account.warehouse_detail;
    }
    // If warehouse list has only one warehouse, set it to default warehouse
    let warehouse_id_list = Object.keys(warehouse_detail);
    if (warehouse_id_list.length === 1) {
      default_warehouse = warehouse_id_list[0];
    }
    let fullname = '';
    if (account.firstname) fullname += account.firstname;
    if (account.lastname) fullname += ' ' + account.lastname;
    fullname = fullname.trim();
    if (!fullname) fullname = account.user_name;
    localStorage.setItem('fullname',fullname);

    // set the tokens
    localStorage.setItem('token', account.token);
    localStorage.setItem('user_id', account.user_id);
    localStorage.setItem('username', account.user_name);
    localStorage.setItem('role', account.role);
    localStorage.setItem('role_type', account.role_type);
    localStorage.setItem('customer_id', (account.customer_id ? account.customer_id : ''));
    localStorage.setItem('customer_name', (account.customer_id ? account.name : ''));
    localStorage.setItem('warehouse_id', (account.warehouse_id ? account.warehouse_id : default_warehouse));
    localStorage.setItem('category_list', JSON.stringify(account.category_list || []));
    localStorage.setItem('agent_list', JSON.stringify(account.agent || []));

    localStorage.setItem('method_list', JSON.stringify(account.method_list || []));
    localStorage.setItem('country_list', JSON.stringify(account.country_list || []));

    let customer_detail = (account.customer_detail && !_.isEmpty(account.customer_detail)) ? account.customer_detail : null;
    if (account.customer_detail) localStorage.setItem('customer_detail', JSON.stringify(customer_detail));

    localStorage.setItem('warehouse_detail', JSON.stringify(warehouse_detail));
    // localStorage.setItem('menu_list', JSON.stringify(account.permission));
    let selectMode = 'w';
    if (account.warehouse_id) selectMode = 'c';
    localStorage.setItem('select_mode', selectMode);

    let warehouse_map = {};
    for (let warehouse of resp.data.warehouse_detail) 
    {
      warehouse_map[warehouse.warehouse_id] = warehouse;
    }
    localStorage.setItem('warehouse_map', JSON.stringify(warehouse_map)); // map warehouse id to name
    let customer_warehouse_list = {}; // listing the supported warehouse list for each customer, grouped by customer_id
    if (resp.data.customer_detail && !_.isEmpty(resp.data.customer_detail))
    {
      // Login as warehouse role, showing multiple customers
      for (let warehouse_id in resp.data.customer_detail)
      {
        let warehouse_name = warehouse_map[warehouse_id].name;
        for (let single_customer of resp.data.customer_detail[warehouse_id])
        {
          if (!customer_warehouse_list[single_customer.customer_id])
          {
            customer_warehouse_list[single_customer.customer_id] = {
              customer_name:  single_customer.shortname,
              customer_id: single_customer.customer_id,
              warehouse_list: []
            };
          }
          customer_warehouse_list[single_customer.customer_id]['warehouse_list'].push({
            warehouse_id: warehouse_id,
            name: warehouse_name,
          });
        }
      }
    } else
    {
      // Login as customer role, showing only one customer
      customer_warehouse_list[resp.data.customer_id] = {
        customer_name:  resp.data.name,
        customer_id: resp.data.customer_id,
        warehouse_list: resp.data.warehouse_detail
      };
    }
    localStorage.setItem('customer_warehouse_list', JSON.stringify(customer_warehouse_list)); // showing customer_id map of customer supported warehouse list

    // Page Permissions
    let menu_permissions = {};
    for (let menu of (account.permission ? account.permission : [])) {
        menu_permissions[menu.menu_key] = menu.menu_label;
    }
    localStorage.setItem('menu_list', JSON.stringify(menu_permissions));
    this.context.role.set(account.role);
    this.context.roleType.set(account.role_type);
    this.context.customer.set(account.customer_id);
    // if (account.role_type === 'customer') {
    //   this.props.history.push("/clientDashboard");
    // } else {
    //   this.props.history.push("/dashboard");
    // }
    if (account.role_type === 'customer') {
      this.props.history.push("/clientDashboard");
    } else {
      this.props.history.push("/dashboard");
    }
  }
  loginError = (err) => {
    this.loginOnce = _.once(this.loginAjax);
    this.setState({loading: false});
    let msg = '';
    if (err.response) {
      msg = err.response.data.Error;
    } else {
      msg = 'Internal Server Error';
    }
    this.setState({feedback_msg: msg,  feedback_type: "error",});
  }
  // Resend Email
  resendEmail = (e) => {
    e.preventDefault();
    const { username } = this.state;
    // todo might need to format username remove special char
    let formatted_username = username;
    // Todo might not allow - and _
    let regex = new RegExp(/[^a-zA-Z0-9-_]/g);
    // if (regex.test(username)) {
    //   // Todo might not allow - and _
    //   this.setState({feedback_msg: "Username can only contain letters, number, underscore and hyphen", feedback_type: "error"});
    //   return;
    // }
    // Todo, removed username special chars
    formatted_username = formatted_username.replace(regex, "");

    this.resendEmailOnce(formatted_username);
  }
  resendEmailAjax= (username) => {
    let req = axios.get(`${utils.getBaseUrl('customer')}/resetpassword/${username}`);
    req.then(this.resendEmailSuccess).catch(this.resendEmailError);
    this.setState({loading: true,
      // error: ''
    });
  }
  resendEmailOnce = _.once(this.resendEmailAjax);
  resendEmailSuccess = (resp) => {
    this.resendEmailOnce = _.once(this.resendEmailAjax);
    this.setState({loading: false});

    if (resp.data.Error) {
      let msg = resp.data.Error;
      this.setState({ feedback_msg: msg,  feedback_type: "error", });
      return;
    }

    if (resp.data) {
      if (this.state.countdown_interval) clearInterval(this.state.countdown_interval);
      let countdown_interval = setInterval(()=>{
        if (this.state.resend_countdown > 0) {
          this.setState({resend_countdown: this.state.resend_countdown-1});
        }
      }, 1000);
      // Todo Might use a snack bar
      // alert("A new verification code has been sent to your email");
      this.setState({
        feedback_msg: "A new verification code has been sent to your email",
        feedback_type: "success",
        resend_countdown: 60,
        countdown_interval
      });
    } else {
      this.setState({ feedback_msg: "Internal Server Error", feedback_type: "error" });
    }
  }
  resendEmailError = (err) => {
    this.resendEmailOnce = _.once(this.resendEmailAjax);
    this.setState({loading: false});
    let msg = "Internal server error";
    this.alert(msg);
  }
  // Login Via code
  loginViaCode = (e) => {
    e.preventDefault();
    const { verification_code } = this.state;
    // Todo might not allow - and _
    let regex = new RegExp(/[^a-zA-Z0-9-_]/g);

    this.loginViaCodeOnce(verification_code.replace(regex, ""));
  }
  loginViaCodeAjax = (access_code) => {
    let req = axios.get(`${utils.getBaseUrl('customer')}/login/${access_code}`);
    req.then(this.loginViaCodeSuccess).catch(this.loginViaCodeError);
    this.setState({
      loading: true,
      // error: ''
    });
  }
  loginViaCodeOnce = _.once(this.loginViaCodeAjax);
  loginViaCodeSuccess = (resp) => {
    this.loginViaCodeOnce = _.once(this.loginViaCodeAjax);
    this.setState({loading: false});

    if (resp.data.Error) {
      let msg = resp.data.Error;
      this.setState({ feedback_msg: msg, feedback_type: "error" });
      return;
    }

    if (resp.data) {
      this.setState({ feedback_msg: "Verification passed! You can login now", feedback_type: "success", verification_required: false});
      return;
    } else {
      this.setState({  feedback_msg: "Internal Server Error", feedback_type: "error"  });
    }
  }
  loginViaCodeError = (err) => {
    this.loginViaCodeOnce = _.once(this.loginViaCodeAjax);
    this.setState({loading: false});
    let msg = "Internal server error";
    this.alert(msg);
  }

  renderAlert = () => {
    const { message_type, message_content } = this.state;

    if (!message_content) return;

    return (
      <PopupAlert
        open={true}
        header={message_type}
        type={message_type}
        content={message_content}
        onClose={this.closeAlert}
      />
    );
  }
  closeAlert = ()=>{
    this.setState({message_type: "", message_content: "" })
  }
  alert = (message, type) => {
    if (!type) type = "Error";
    this.setState({message_content: message, message_type: type});
  }
}
export default withRouter(withStyles(styles)(Login));
