import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, FormControl, InputLabel, Select, MenuItem, withStyles, IconButton } from '@material-ui/core';
import {
  CardContainer,
  CustomInput,
  Button,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from "lodash";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class NewWMSUser extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: {
        type: 'warehouse',
        user_role: '',
        username: '',
        password: '',
        shift: '1', 
        firstname: '',
        lastname: '',
        email: '',
        rate: '',
        agent: '',
        employee_id: ''
      },
      role_options: [],
    };

    document.title = 'New User';
  }

  componentWillMount() {
    this.loadRoles();
  }

  loadRoles = () => {
    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/userrole`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    });

    this.setState({loading: true});
    req.then(this.loadRolesSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadRolesSuccess = (resp) => {
      this.setState({loading: false});

      // alert error if any
      if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
      }

      let role_name_map = {};
      for (let role of resp.data.role) {
          role_name_map[role.role_id] = role.role_name;
      }
      this.role_name_map = role_name_map;

      this.setState({role_options: resp.data.role});
  }
  createUser = () => {
    const {user} = this.state;

    let err = '';
    if (!user.user_role) err += 'User role is required.\n';
    if (!user.username) err += 'Username is required.\n';
    if (user.username.length < 6) err += 'Username should have at least 6 characters!\n';
    if (!user.password) err += 'Password is required.\n';
    if (err) {
      this.context.alert(err);
      return;
    }

    this.createUserOnce({
      user_type: user.type,
      user_role: user.user_role,
      // customer: user.customer,
      username: user.username,
      password: user.password,
      shift: user.shift,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      rate: user.rate,
      agent: user.agent,
      employee_id: user.employee_id,
    });
  }
  createUserAjax = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/warehouseuser`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.createUserSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  createUserSuccess = (resp) => {
    this.createUserOnce = _.once(this.createUserAjax);
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
      this.context.snackDisplay("Create user success.");
      window.location.reload();
    }
  }
  resetSubmitOnce = () => {this.createUserOnce = _.once(this.createUserAjax);} // reset the submit picking once after the ajax call returns
  createUserOnce = _.once(this.createUserAjax);

  onUserChange = (val, key) => {
    let newUser = Object.assign({}, this.state.user);
    newUser[key] = val;
    this.setState({user: newUser});
  }

  render() {
    const {classes} = this.props;
    const { loading, role_options } = this.state;
    const { user_role, username, password, firstname, lastname, email, rate, agent, employee_id } = this.state.user;
      
    return (
      <Grid container spacing={2}>
          {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

          <GridItem xs={12} sm={6}>
            <CardContainer>
              <div>
                <FormControl required fullWidth className={classes.selectInput}>
                  <InputLabel htmlFor="user_role" shrink>Role</InputLabel>
                  <Select
                    value={parseInt(user_role) || ''}
                    onChange={(e)=>{this.onUserChange(e.target.value, 'user_role')}}
                    inputProps={{
                      name: 'user_role',
                      id: 'user_role',
                    }}
                  >
                    {role_options.map((val)=>{ return (<MenuItem key={val.role_id} value={parseInt(val.role_id)}>{val.role_name}</MenuItem>);})}
                  </Select>
                </FormControl>

                <CustomInput
                  labelText='Username'
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'username')},
                    value: username,
                  }}
                />

                <CustomInput
                  labelText='Password'
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'password')},
                    value: password,
                    type: 'password'
                  }}
                />

                <CustomInput
                  key='firstname'
                  labelText='First Name'
                  formControlProps={{
                    fullWidth: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'firstname')},
                    value: firstname,
                  }}
                />

                <CustomInput
                  key='lastname'
                  labelText='Last Name'
                  formControlProps={{
                    fullWidth: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'lastname')},
                    value: lastname,
                  }}
                />

                <CustomInput
                  key='email'
                  labelText='Email'
                  formControlProps={{
                    fullWidth: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'email')},
                    value: email,
                  }}
                />

                <CustomInput
                  key='rate'
                  labelText='Rate'
                  formControlProps={{
                    fullWidth: true,
                    className: this.props.classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'rate')},
                    value: rate,
                  }}
                />

                <FormControl fullWidth className={classes.selectInput}>
                  <InputLabel shrink>Company</InputLabel>
                    <Select
                      value={agent}
                      onChange={(e)=>{this.onUserChange(e.target.value, 'agent')}}
                    >
                    <MenuItem value='TEMP'>TEMP</MenuItem>
                    <MenuItem value='BXZ STAFF'>BXZ STAFF</MenuItem>
                    <MenuItem value='BXZ PT'>BXZ PT</MenuItem>
                  </Select>
                </FormControl>

                <CustomInput
                  labelText='Employee Number'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onChange: (e)=>{this.onUserChange(e.target.value, 'employee_id')},
                    value: employee_id || '',
                    type: 'number',
                    inputProps:{
                        min: 0,
                        step: 1
                    }
                  }}
                />

                <Button style={{marginTop: '1rem'}} onClick={this.createUser}>New User</Button>
              </div>
            </CardContainer>
          </GridItem>
        </Grid>
    );
  }
}
export default withStyles(styles)(NewWMSUser);
