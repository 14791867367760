import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, ButtonGroup, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import {
  Check,
  Block
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class CountByScanner extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      batch: null,
      location: null,
      sku: null,
    };

    this.temp_data = {
      batch_id: '',
      counting_location: '',
      sku_map: null,
      upc_map: null,
      table_keys: null,
      verified_location: null,
      barcode_map: {},
    };

    this.state = {
      loading: false,
      items: {},
      batch_id: '',
    };

    document.title = "Count By Scanner";
  }

  resetPage = () => {
    this.temp_data = {
      batch_id: '',
      counting_location: '',
      sku_map: null,
      upc_map: null,
      table_keys: null,
      verified_location: null,
    };

    if (this.input_refs.batch) {
      this.input_refs.batch.value = '';
      this.input_refs.batch.focus();
    }

    this.resetSubmitOnce();

    this.setState({
      items: {},
      batch_id: '',
      forceUpdate: {},
    });
  }

  verifyLocation = () => {
    if (!this.input_refs.location || !this.input_refs.location.value) return;
    let location = this.input_refs.location.value; 

    if (location != this.temp_data.counting_location) {
      this.input_refs.location.value = '';
      _.delay(()=>{if (this.input_refs.location) this.input_refs.location.focus()}, 1);
      this.context.alert(`This is not the correct location, please go to ${this.temp_data.counting_location} (Esta no es la ubicación correcta, vaya a ${this.temp_data.counting_location})`);
    } else {
      _.delay(()=>{if (this.input_refs.sku) this.input_refs.sku.focus()}, 1);
      this.temp_data.verified_location = location;
      this.setState({forceUpdate: {}});
    }
  }
  handleLocationScan  = _.debounce(this.verifyLocation, 150);
  matchSKU = () => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;
    scanned_val = scanned_val.toUpperCase();

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) {
      matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
    } else if (this.temp_data.upc_map[scanned_val]) {
      matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
    } else {
      this.verifySku(scanned_val);
      return;
    }
    let items = Object.assign({}, this.state.items);
    let newItem = Object.assign({}, items[matched_id]);
    let prev_qty = (parseInt(newItem.quantity_input) || 0);
    let newQty = 1 + (prev_qty == -1 ? 0 : prev_qty);
    newItem.verified = true;
    newItem['quantity_input'] = newQty;
    if (prev_qty == -1) {
      this.context.confirm('You are scanning a skipped item, are you sure to un-skip and count it?' , this.updateItemQuantity.bind(this, matched_id, newItem));
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }
      return; // don't update item quantity unless confirmed
    }
   
    items[matched_id] = newItem;
    // Move the scanned row to top
    let newKeys = [];
    newKeys.push(matched_id);
    for (let id of this.temp_data.table_keys) {
      if (id != matched_id) {
        newKeys.push(id);
      }
    }
    this.temp_data.table_keys = newKeys;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({items: items});
  }
  updateItemQuantity = (matched_id, newItem) => {
    let items = Object.assign({}, this.state.items);
    items[matched_id] = newItem;

    let newKeys = [];
    newKeys.push(matched_id);
    for (let id of this.temp_data.table_keys) {
      if (id != matched_id) {
        newKeys.push(id);
      }
    }
    this.temp_data.table_keys = newKeys;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({items: items, forceUpdate: {}});
  }
  handleSKUScan = _.debounce(this.matchSKU, 150);
  verifySku = (scanned_val) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving/${scanned_val}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.verifySkuSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  verifySkuSuccess = (resp) => {
    this.setState({loading: false});
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }  
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let verified_item = resp.data[0];
    let new_items = Object.assign({}, this.state.items);
    let new_keys = [verified_item.sku]; // move matched to top
    if (new_items[verified_item.sku]) {
      // if item already exists 
      let new_item = new_items[verified_item.sku];
      let prev_qty = (parseInt(new_item.quantity_input) || 0);
      new_item.quantity_input = 1 + (prev_qty == -1 ? 0 : prev_qty);
      new_item.verified = true;
      if (prev_qty == -1) {
        this.context.confirm('You are scanning a skipped item, are you sure to un-skip and count it?' , this.updateItemQuantity.bind(this, verified_item.sku, new_item));
        return; // don't update item quantity unless confirmed
      }
      // move matched to top
      for (let id of this.temp_data.table_keys) {
        if (id != verified_item.sku) {
          new_keys.push(id);
        }
      }
    } else {
      // new item, insert
      verified_item.quantity_input = 1;
      verified_item.id = ""; // the cycle count DB id, does not exist for new added item
      verified_item.sku = verified_item.sku || "";
      verified_item.upc = verified_item.upc || "";
      verified_item.new_add = true; // this item is added during counting, not systemly at the location before. Should not skip.
      verified_item.verified = true;
      new_items[verified_item.sku] = verified_item;   
      if (!this.temp_data.sku_map[verified_item.sku]) this.temp_data.sku_map[('' + verified_item.sku).toUpperCase()] = verified_item.sku;
      if (!this.temp_data.upc_map[verified_item.upc]) this.temp_data.upc_map[('' + verified_item.upc).toUpperCase()] = verified_item.sku;
      // move matched to top
      new_keys = new_keys.concat(this.temp_data.table_keys);
    }
    this.temp_data.table_keys = new_keys;

    this.setState({items: new_items});
  }

  batchSearch = () => {
    // let keyword = utils.formatString(this.input_refs.batch_id.value);
    let keyword = utils.formatString(this.state.batch_id);
    if (!keyword) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/cyclecountbatch/${keyword}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true, items: {}});
    this.temp_data = {
      batch_id: '',
      counting_location: '',
      sku_map: null,
      upc_map: null,
      table_keys: null,
      verified_location: null,
      barcode_map: {},
    };
    
    req.then(this.batchSearchSuccess.bind(this, keyword)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  batchSearchSuccess = (batch_id, resp) => {
    this.setState({loading: false});
    let result = resp.data;
    if (result.Error) {
      if (Array.isArray(result.Error)) this.context.alert(result.Error.join("\n"));
      else this.context.alert(result.Error);
      return;
    }

    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    if (result.length <= 0) {
      this.context.alert("No location information");
      return;
    }
    let location = result[0]['location'];
    this.temp_data.batch_id = batch_id;
    this.temp_data.counting_location = location;
    this.temp_data.verified_location = null;
    for (let item of result) {
      // let id = `${item.sku}_${item.order_id}`;
      let id = `${item.sku}`;
      if (!item.quantity_input) item.quantity_input = 0;
      items[id] = item;
      let sku = item.sku ? ('' + item.sku).toUpperCase() : "";
      let upc = item.upc ? ('' + item.upc).toUpperCase() : "";
      // allows duplicate sku and upc, use sku/upc + tote to identify
      if (!skus[sku]) skus[sku] = id;
      if (!upcs[upc]) upcs[upc] = id;
      keys.push(id);
    }
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;
    this.setState({items: items});
  }
  skipCounting = () => {
    const {items} = this.state;
    const { batch_id } = this.temp_data;
    
    let err = '';
    let counted_items = [];
    for (let line_item_id in items) {
      let line_item = items[line_item_id];
      line_item.quantity_input = -1;
      counted_items.push(line_item);
    }
    if (err) {
      this.context.alert(err);
      return;
    }

    this.submitCountingOnce({items: counted_items, batch_id, location: this.temp_data.counting_location});
  }
  submitCounting = () => {
    const {items} = this.state;
    const { batch_id } = this.temp_data;
    
    let err = '';
    let counted_items = [];
    let skipped_all = true;
    for (let line_item_id in items) {
      let line_item = items[line_item_id];
      if (!line_item.id && line_item.quantity_input <= 0) continue;
      if (line_item.quantity_input !== -1) {
        skipped_all = false;
      }
      if (line_item.quantity_input == -1 && line_item.new_add) continue; // if this item is added from front-end and skipped, don't pass to backend
      counted_items.push(line_item);
    }
    if (err) {
      this.context.alert(err);
      return;
    }

    if (skipped_all) {
      this.context.confirm("You have skipped all items for this locaion, are you sure to submit counting?", ()=>{
        this.submitCountingOnce({items: counted_items, batch_id, location: this.temp_data.counting_location});
      });
    } else {
      this.submitCountingOnce({items: counted_items, batch_id, location: this.temp_data.counting_location});
    }
  }
  submitCountingAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/cyclecountbatch/${this.temp_data.batch_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitCountingSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitCountingOnce = _.once(this.submitCountingAJax);} // reset the submit Counting once after the ajax call returns
  submitCountingOnce = _.once(this.submitCountingAJax)
  submitCountingSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;

    if (result.finished) {
      // this.context.snackDisplay("Counting finished.", {hideCallback: ()=>{
      //   window.location.reload();
      // }});
      this.context.snackDisplay("Counting finished.");
      this.resetPage();
    } else {
      let items = {};
      let skus = {};
      let upcs = {};
      let keys = [];
      if (result.length <= 0) {
        this.context.alert("No location information");
        return;
      }
      let location = result[0]['location'];
      this.temp_data.counting_location = location;
      this.temp_data.verified_location = null;
      for (let item of result) {
        let id = `${item.sku}`;
        if (!item.quantity_input) item.quantity_input = 0;
        items[id] = item;
        let sku = item.sku ? ('' + item.sku).toUpperCase() : "";
        let upc = item.upc ? ('' + item.upc).toUpperCase() : "";
        // allows duplicate sku and upc, use sku/upc + tote to identify
        if (!skus[sku]) skus[sku] = id;
        if (!upcs[upc]) upcs[upc] = id;
        keys.push(id);
      }
      this.temp_data.sku_map = skus;
      this.temp_data.upc_map = upcs;
      this.temp_data.table_keys = keys;
      this.setState({items: items});
    }
  }

  renderCountingTable = () => {
    const { classes } = this.props;
    const { items } = this.state;
    const { counting_location, verified_location, table_keys } = this.temp_data;
    if (!items || _.isEmpty(items) || table_keys.length === 0) return null;

    let content = null;

    if (verified_location && verified_location == counting_location) {
      let rows = [];
      for (let item_id of this.temp_data.table_keys) {
        rows.push(items[item_id]);
      }

      let columns = [
        {
          key: 'sku',
          label: 'SKU',
          contentNoWrap: true,
        },
        {
          key: 'upc',
          label: 'UPC',
          contentNoWrap: true
        },
        // {
        //   key: 'system_quantity',
        //   label: 'System Quantity',
        //   contentNoWrap: true,
        //   render: (val, key, row)=>0
        // },
        {
          key: 'quantity_input',
          label: 'Scanned',
          render: (val, key, row)=>{
            if (val == -1) return 'Skipped';
            return (
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  disabled: !row.verified,
                  onChange: (e)=>{
                    let new_val = parseInt(e.target.value);
                    if (isNaN(new_val) || new_val < 0) return;
                    let new_items = Object.assign({},this.state.items);
                    let new_item = new_items[row.sku]; 
                    if (new_val > 10000) {
                      this.context.confirm("You just entered a very large quantity, are you sure this is correct? (Acaba de ingresar una cantidad muy grande, ¿está seguro de que es correcto?)", ()=>{
                        new_item.quantity_input = new_val;
                        this.setState({items: new_items});
                      });
                    } else {
                      new_item.quantity_input = new_val;
                      this.setState({items: new_items});
                    }
                  },
                  // value: row.quantity_input || '',
                  value: row.quantity_input,
                  type: 'number',
                  inputProps: {
                    min: 0,
                    step: 1,
                  }
                }}
              />
            );
          }
        },
        // {
        //   key: 'lotcode',
        //   label: 'Lotcode',
        //   render: (val, key, row)=>{
        //     return (
        //       <CustomInput
        //         formControlProps={{
        //           fullWidth: true,
        //           className: this.props.classes.customInput
        //         }}
        //         inputProps={{
        //           onChange: (e)=>{
        //             let new_val = e.target.value;
        //             let new_items = Object.assign({},this.state.items);
        //             let new_item = new_items[row.sku]; 
        //             new_item.lotcode = new_val;
        //             this.setState({items: new_items});
        //           },
        //           value: row.lotcode || '',
        //         }}
        //       />
        //     );
        //   }
        // },
        {
          key: 'skipped',
          label: 'Skipped',
          render: (val, key, row)=>{
            // if (row.new_add) return ''; // can't skip item that does not exist on the backend

            if (row.quantity_input == -1) {
              return (
                <IconButton size="small" onClick={()=>{
                  this.context.confirm("Are you sure to unskip the counting of this item?", ()=>{
                    row["quantity_input"] = 0;
                    this.setState({items: items});
                  });
                }} color='secondary' variant="fab" className={classes.button}>
                  <Check/>
                </IconButton>
              );
            } else {
              return (
                <IconButton size="small" onClick={()=>{
                  this.context.confirm("Are you sure to skip the counting of this item?", ()=>{
                    row["quantity_input"] = -1;
                    this.setState({items: items});
                  });
                }} color='secondary' variant="fab" className={classes.button}>
                    <Block/>
                </IconButton>
              );
            }
          }
        },
      ];

      content = (
        <div>
          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            {this.temp_data.counting_location}
          </Typography>
          <div style={{width: '100%',display: 'inline-flex'}}>
            <div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>
              <CustomInput
                labelText='SKU/UPC'
                formControlProps={{
                    fullWidth: false,
                    style: {marginLeft: '.25rem', marginRight: '.75rem'},
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    onKeyUp: this.handleSKUScan,
                    onPaste: (e)=>{e.preventDefault();},
                    inputRef: elem=>this.input_refs.sku = elem
                }}
              />
            </div>
            <div style={{width: '50%', display: 'inline-flex', alignItems: 'center'}}>{<Button style={{marginLeft: ".25rem"}} onClick={this.submitCounting}>Submit Counting</Button>}</div>
          </div>

          {/* <div className={classes.scrollContainer}>
            {item_list}
          </div> */}

          <NewDataTable
            rows={rows}
            // rowHeight={50} 
            maxHeight={500}
            // rowsPerPage={50}
            noPagination
            columns={columns}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <div className={classes.title} >
            <Typography variant="h6">
              {this.temp_data.counting_location}
            </Typography>
          </div>
  
          <CustomInput
            labelText='Verify Location'
            formControlProps={{
              fullWidth: false,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              // disabled: !!verified_location,
              onKeyUp: this.handleLocationScan,
              onPaste: (e)=>{e.preventDefault();},
              inputRef: elem=>this.input_refs.location = elem
            }}
          />

          <Button style={{marginTop: '.5rem', marginLeft: "1rem"}} onClick={()=>{this.context.confirm('Are you sure to skip counting this location?', this.skipCounting)}}>Skip Location</Button>
          <br/>
        </div>
      );
      _.delay(()=>{this.input_refs.location.focus()}, 1); // if need verify locaiton, focus it
    }

    return (
      <GridItem xs={12}>
        <CardContainer>
          {content}
        </CardContainer>
      </GridItem>
    );
  }
 
  render() {
    const { classes } = this.props;
    const { loading, batch_id } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <CustomInput
              labelText='Batch id'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                // defaultValue: '',
                value: batch_id,
                onChange: (e) => {this.setState({batch_id: e.target.value})},
                disabled: loading,
                inputRef: elem => this.input_refs.batch_id = elem
              }}
            />

            <Button onClick={this.batchSearch} className={classes.button}>Search</Button>
          </CardContainer>
        </GridItem>

        {this.renderCountingTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(CountByScanner);
