import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import moment from 'moment-timezone'
import {Typography, Popover, FormGroup, Accordion, AccordionSummary, AccordionDetails, Hidden, Grid, IconButton, InputLabel, Select, FormControl, FormControlLabel, Switch, ButtonGroup,  withStyles, Menu, MenuItem, Checkbox, ListItemText } from "@material-ui/core"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
ArrowDropDown,
Add,
DeleteForever,
Edit,
ExpandMore
} from '@material-ui/icons';
import {
  AjaxTable,
  KeyValueTable,
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
  MultiSelect
} from 'components';
import EditOrderDetail from 'views/Dialogs/EditOrderDetail'
import ReturnLabel from 'views/Dialogs/ReturnLabel'
import EditListOrderDetail from 'views/Dialogs/EditListOrderDetail'
import EditPalletTracking from 'views/Dialogs/EditPalletTracking'
import EditOrderShipment from 'views/Dialogs/EditOrderShipment'
import EditOrderAddress from 'views/Dialogs/EditOrderAddress'
import EditOrderItem from 'views/Dialogs/EditOrderItem'
import AddComment from 'views/Dialogs/AddComment'
import utils from 'utils/utils'
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

const statusMap = {
  UNBATCH: {
    value: 'UNBATCH',
    label: 'UNBATCH',
  },
  PICKING: {
    value: 'PICKING',
    label: 'PICKING',
  },
  PICKED: {
    value: 'PICKED',
    label: 'PICKED',
  },
  HOLDING: {
    value: 'HOLDING',
    label: 'HOLDING',
  },
  VERIFIED: {
    value: 'VERIFIED',
    label: 'VERIFIED',
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'CANCELLED',
  },
  BACKORDER: {
    value: 'BACKORDER',
    label: 'BACKORDER',
  },
  SHIPPED: {
    value: 'SHIPPED',
    label: 'SHIPPED',
  },
  RETURNED: {
    value: 'RETURNED',
    label: 'RETURNED',
  }
};

const typeMap = {
  retail: {
    value: 'retail',
    label: 'Retail',
  },
  'whole-sale': {
    value: 'whole-sale',
    label: 'Wholesale',
  },
  edi: {
    value: 'edi',
    label: 'EDI',
  },
  gift: {
    value: 'gift',
    label: 'Gift',
  },
  monogram: {
    value: 'monogram',
    label: 'Monogram',
  },
  dropship: {
    value: 'dropship',
    label: 'Dropship',
  },
  'final-sale': {
    value: 'final-sale',
    label: 'Final-sale',
  }
};

const printPackingSlipStatus = {
  PICKING: true,
  PICKED: true,
  VERIFIED: true,
  SHIPPED: true,
  RETURNED: true,
};

const invoiceStatus = {
  SHIPPED: true,
  RETURNED: true,
};

const itemEditStatus = {
  UNBATCH: true,
  HOLDING: true,
  BACKORDER: true,
};

const listCancelStatus = {
  UNBATCH: true,
  HOLDING: true,
  BACKORDER: true,
  CANCELLED: true,
}

const showCustomerComments = {
  '16': true,
  '199': true,
  '192': true,
  '201': true,
  '212': true,
  '216': true,
}

class ManageOrder extends Component {
  static contextType = DataContext

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.current_page = 0;
    this.updatePage = null;

    this.methodList = localStorage.getItem('method_list') ? JSON.parse(localStorage.getItem('method_list')) : [];
    let methodMap = {};
    for (let method of this.methodList) {
      methodMap[method.method_value] = method.method_label;
    }
    this.methodMap = methodMap;
    this.countryList = localStorage.getItem('country_list') ? JSON.parse(localStorage.getItem('country_list')) : [];

    // Provide the list of warehouse for user to switch order warehouse
    // Now listing all warehouses, later might need to changed to only list customer warehuoses (that are setup before using)
    // Todo use customer warehouse list to show warehouse switch options
    // const warehouse_id_map = JSON.parse(localStorage.getItem('warehouse_map'));
    // const customer_warehouse_list = JSON.parse(localStorage.getItem('customer_warehouse_list'));
    // const current_customer_w_list = customer_warehouse_list[localStorage.getItem('customer_id')].warehouse_list;
    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let warehouse_options = [];
    for (let w_id in warehouse_detail) {
      warehouse_options.push({selected: false, value: w_id, label: warehouse_detail[w_id]});
    }
    this.warehouse_options = warehouse_options;

    // search field input refs for uncontrolled 
    // this.searchByInput = null;
    this.searchForInput = null;
    this.searchDateFromInput = null;
    this.searchDateToInput = null;
    this.trackingNumberInput = null;

    this.displayDB = false;

    this.state = {
      loading: false,
      actionsAnchor: null,
      singleActionsAnchor: null,
      orderList: [],
      singleOrder: null,
      activeCartonIndex: -1,
      selectedOrders: {},
      renderingAjax: true,
      searchBy: 'order',
      displayFilters: {
        status: {
          UNBATCH: true,
          PICKING: true,
          PICKED: true,
          HOLDING: true,
          VERIFIED: true,
          CANCELLED: true,
          BACKORDER: true,
          SHIPPED: true,
          RETURNED: true,
        },
        type: {
          retail: true,
          'whole-sale': true,
          edi: true,
          gift: true,
          monogram: true,
          dropship: true,
          'final-sale': true,
        },
      },
      forceUpdate: {},
    };

    document.title = "Manage Orders";
  }

  componentDidMount() {
    let order_id = localStorage.getItem("dbOrder");
    if (order_id) {
      localStorage.removeItem("dbOrder");
      this.displayDB = true;
      this.loadOrderByID(order_id, true);
    } else {
      this.loadOrderByPage();
    }
  }

  loadOrderByPage = (page, updatePage) => {
    this.updatePage = updatePage;
    page = page || 0;
    // let req = axios({
    //   method: 'get',
    //   url: `${utils.getBaseUrl('customer')}/order/${page}`,
    //   headers: {
    //     token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
    //   },
    // });
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderfilter`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: Object.assign({page}, this.state.displayFilters)
    });

    this.setState({loading: true});
    req.then(this.loadOrderByPageSuccess.bind(this, page, updatePage)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadOrderByPageSuccess = (newPage, updatePage, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data.length == 0) {
      this.context.alert("Already last page.");
      return;
    } 

    this.current_page = newPage;
    this.setState({selectedOrders: {}});
    this.setState({orderList: resp.data});
    if (updatePage) updatePage();
  }
  loadOrderByID = (order_id, loadingDB) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/orderdetail/${order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    if (!loadingDB) this.displayDB = false;

    // clear single order data when loading a new one
    this.setState({singleOrder: null, });

    this.setState({loading: true});
    req.then(this.loadOrderByIDSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadOrderByIDSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // save previous lineitem quantity. For future update compare
    resp.data.item = (resp.data.item || []).map((elem)=>{
      elem.prev_quantity = elem.quantity;
      return elem;
    }); 
    let cartons = [];
    for (let carton_id in resp.data.carton) {
      cartons.push(resp.data.carton[carton_id]);
    }

    resp.data.carton = cartons;
    this.setState({
      singleOrder: resp.data
    });

    utils.scrollToTop();
  }
  searchOrder = () => {
    // let searchBy = this.searchByInput ? this.searchByInput.value : '';
    let searchBy = this.state.searchBy;
    let searchFor = this.searchForInput ? this.searchForInput.value : '';
    if (searchBy !== 'all') searchFor = utils.formatString(searchFor, {multiline: true}).split(','); // if not search by Other, pass array

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderSearch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: searchBy,
        keyword: searchFor
      },
    });
    this.setState({selectedOrders: {}});
    this.displayDB = false;
    this.setState({loading: true});
    req.then(this.searchOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  searchOrderSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({
      orderList: resp.data,
      renderingAjax: false,
      singleOrder: null,
    });
  }
  searchOrderByDate = () => {
    let dateFrom = this.searchDateFromInput ? this.searchDateFromInput.value : '';  
    let dateTo = this.searchDateToInput ? this.searchDateToInput.value : '';  

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderSearch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'date',
        keyword: [dateFrom, dateTo]
      },
    });

    this.displayDB = false;
    this.setState({selectedOrders: {}});
    this.setState({loading: true});
    req.then(this.searchOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadPackingSlip = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/packsingslip/${this.state.singleOrder.order.order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadPackingSlipSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadPackingSlipSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    utils.printPage(resp.data);
  }
  printUCCLabel = () => {
    const { order } = this.state.singleOrder;
    let order_key = order.order_key;

    // Check customer id and local storage customer id
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    let newTab = window.open(utils.getBaseUrl('') + `/edicartonLabel/${localStorage.getItem('customer_id')}/${order_key}`);
    if (!newTab) alert("Unable to open new tab, please change browser settings to allow pop-up.");
  }
  getCartonLabel = () => {
    const { order } = this.state.singleOrder;
    let order_key = order.order_key;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('')}/printCartonLabel/${localStorage.getItem('customer_id')}/${order_key}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.getCartonLabelSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  getCartonLabelSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    utils.printPage(resp.data);
  }
  loadOrderInvoice = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/commercialinvoice/${this.state.singleOrder.order.order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadOrderInvoiceSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadOrderInvoiceSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    utils.printPage(resp.data);
  }
  getReturnLabel = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/returnLabel/${localStorage.getItem('customer_id')}/${this.state.singleOrder.order.order_id}/YES`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true, dialog: ''});
    req.then(this.getReturnLabelSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  getReturnLabelSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let newOrder = Object.assign({}, this.state.singleOrder);
    let return_label = Array.from(newOrder.return_label) || [];

    switch (true) {
      case typeof resp.data === 'string': // result is a plain string url
        return_label.push({
          tracking: '',
          tracking_label: resp.data,
        });
        break; 
      case Array.isArray(resp.data) && resp.data.length > 0: // result is an array of label
        if (typeof resp.data[0] === 'stirng') {
          // array[0] is url, array[1] is tracking number
          return_label.push({
            tracking: (resp.data.length > 1) ? resp.data[1] : '',
            tracking_label: resp.data[0] || '',
          });
        } else {
          // each array elem is label object
          for (let single_label of resp.data) {
            return_label.push(single_label);
          }
        }
        for (let single_label of resp.data) {
          if (typeof single_label === 'string') {
            // each label is a url string
            return_label.push({
              tracking: '',
              tracking_label: single_label || '',
            });
          } else {
            // each label is an object has both url and tracking
            return_label.push({
              tracking: single_label.tracking || '',
              tracking_label: single_label.tracking_label || '',
            });
          }
        }
        break;
      case typeof resp.data === 'object': // result is an object of single return label
        return_label.push(resp.data);
        break;
    }
    
    newOrder.return_label = return_label;

    // if (Array.isArray(resp.data)) {
    //   newOrder.return_label = [resp.data];
    // } else {
    //   newOrder.return_label = [[resp.data, '']];
    // }
    this.setState({singleOrder: newOrder, dialog: 'returnLabel'});
  }
  updateListDetail = (newDetail) => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      // keyword_array.push(this.state.selectedOrders[index]['order_id']);
      // keyword_array.push(this.state.selectedOrders[index]['order_key']); // on 2023-05-16 , Bo requests to use order_key not order id, because need to use order key to find all cartons.
      keyword_array.push({
        order_key: this.state.selectedOrders[index]['order_key'],
        order_id: this.state.selectedOrders[index]['order_id'],
      }); // on 2023-05-18 , Bo requests to use order_key AND order id, because need to use order key to find all cartons and action log needs order id.
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/editorderlist`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id: keyword_array,
        edit_message: newDetail
      },
    });

    this.setState({loading: true});
    req.then(this.updateListDetailSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateListDetailSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // this.context.snackDisplay("Update selected order detail success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Update selected order detail success.");
    window.location.reload();
  }
  onListAddressSubmit = (newAddress) => {
    this.context.confirm("Are you sure to update the address of all selected orders? Note once updated, all previous address can't be restored.",  this.updateListAddress.bind(this, newAddress));
  }
  updateListAddress = (newAddress) => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
      // keyword_array.push(this.state.selectedOrders[index]['order_key']); // on 2023-05-16 , Bo requests to use order_key not order id, because need to use order key to find all cartons.
      // keyword_array.push({
      //   order_key: this.state.selectedOrders[index]['order_key'],
      //   order_id: this.state.selectedOrders[index]['order_id'],
      // }); // on 2023-05-18 , Bo requests to use order_key AND order id, because need to use order key to find all cartons and action log needs order id.
    }    

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/listOrderAddress`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id: keyword_array,
        address: newAddress
      },
    });

    this.setState({loading: true});
    req.then(this.updateListAddressSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateListAddressSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // this.context.snackDisplay("Update selected order detail success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.setState({ dialog: ""});
    this.context.snackDisplay("Update selected order address success.");
  }
  updateOrderDetail = (newOrderDetail) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/order`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newOrderDetail,
    });

    this.setState({loading: true});
    req.then(this.updateOrderDetailSuccess.bind(this, newOrderDetail)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateOrderDetailSuccess = (newOrderDetail, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    
    this.context.snackDisplay("Update order detail success.");

    let newSingleOrder = Object.assign({}, this.state.singleOrder); 
    newSingleOrder.order = newOrderDetail;
    this.setState({
      singleOrder: newSingleOrder,
      dialog: '',
    });
  }
  updateOrderAddress = (newAddress) => {
    newAddress.order_id = this.state.singleOrder.order.order_id;
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderaddress`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newAddress,
    });

    this.setState({loading: true});
    req.then(this.updateOrderAddressSuccess.bind(this, newAddress)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateOrderAddressSuccess = (newAddress, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.context.snackDisplay("Update order address success.");

    let newOrder = Object.assign({}, this.state.singleOrder);
    newOrder.address = newAddress;
    this.setState({
      singleOrder: newOrder,
      dialog: '',
    });
  }
  updateOrderCarton =(newCarton) => {
    newCarton.order_id = newCarton.carton_id;
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/ordershipment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newCarton,
    });

    this.setState({loading: true});
    req.then(this.updateOrderCartonSuccess.bind(this, newCarton)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateOrderCartonSuccess = (newCarton, resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.context.snackDisplay("Update order carton success.");

    let newOrder = Object.assign({}, this.state.singleOrder);
    newOrder.carton = newOrder.carton.map((elem)=>{
      if (elem.carton_id == newCarton.carton_id) return newCarton;
      else return elem;
    });
    this.setState({
      singleOrder: newOrder,
      dialog: '',
    });
  }
  exportOrders = () =>{
    if (_.isEmpty(this.state.selectedOrders)) {
      this.context.alert('No order selected.');
      return;
    }

    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_key']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/exportreport`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'orders',
        keyword: keyword_array
      },
    });

    this.setState({loading: true});
    req.then(this.exportOrdersSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  exportOrdersSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    // this.context.snackDisplay("Export selected orders success.");
    window.open(result.url, '_blank');
  }
  newListLabel = () => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
    }

    this.newListLabelOnce({ 
      type: 'label_order',
      order_id: keyword_array ,
      shipment_detail: [],
      address: {},
      order_detail: {},
    });
  }
  newListLabeljax = (data) => {
    data.type = 'label_order';
    // let req = axios({
    //   method: 'post',
    //   url: `${utils.getBaseUrl('customer')}/newLabelList`,
    //   headers: {
    //     token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
    //   },
    //   data
    // });

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/shipment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    this.setState({loading: true});
    req.then(this.newListLabelSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetNewListLabelOnce}));
  }
  resetNewListLabelOnce = () => {this.newListLabelOnce = _.once(this.newListLabeljax);}
  newListLabelOnce = _.once(this.newListLabeljax)
  newListLabelSuccess = (resp) => {
    this.setState({ loading: false });
    this.resetNewListLabelOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    switch (true) {
      case typeof result === "string":
          utils.printPage(result, true);
          break;
      case Array.isArray(result):
        result.forEach((elem, index)=>{
              if (index == 0) {
                  utils.printPage(elem, true);
              } else {
                  utils.printPage(elem);
              }
          });
          break;
    }
    this.context.snackDisplay("Create labels success.");
  }
  updateListPalletTracking = ({carrier, tracking}) => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/b2bPalletsTracking`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id_list: keyword_array,
        carrier: carrier,
        tracking: tracking,
      }
    });

    this.setState({loading: true});
    req.then(this.updateListPalletTrackingSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateListPalletTrackingSuccess = (resp) => {
    this.setState({ loading: false });
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // this.context.snackDisplay("Updated order pallet tracking success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Updated order pallet tracking success.");
    window.location.reload();
  }
  sendListASN = () => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
    }

    this.sendListASNOnce({ order_id: keyword_array });
  }
  sendListASNAjax = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/sendasn`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.sendListASNSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetSendListASNOnce}));
  }
  resetSendListASNOnce = () => {this.sendListASNOnce = _.once(this.sendListASNAjax);}
  sendListASNOnce = _.once(this.sendListASNAjax)
  sendListASNSuccess = (resp) => {
    this.setState({ loading: false });
    this.resetSendListASNOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // this.context.snackDisplay("Send ASN for selected orders success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Send ASN for selected orders success.");
    window.location.reload();
  }
  releaseOrders = () => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/releasedorder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id_list: keyword_array,
      }
    });

    this.setState({loading: true});
    req.then(this.releaseOrdersSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  releaseOrdersSuccess = (resp) => {
    this.setState({ loading: false });
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // this.context.snackDisplay("Release selected orders success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Release selected orders success.");
    window.location.reload();
  }
  cancelOrderList = () => {
    let keyword_array = [];
    let err = '';
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
      if (!listCancelStatus[this.state.selectedOrders[index].status]) err += `Can only cancel UNBATCH or BACKORDER. Order ${this.state.selectedOrders[index].order_key} is under ${this.state.selectedOrders[index].status}. \n`;
    }

    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/cancelorderlist`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id: keyword_array
      }
    });

    this.setState({loading: true});
    req.then(this.cancelOrderListSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  cancelOrderListSuccess = (resp) => {
    this.setState({ loading: false });
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // this.context.snackDisplay("Cancel selected orders success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Cancel selected orders success.");
    window.location.reload();
  }
  unCancelOrderList = () => {
    let keyword_array = [];
    for (let index in this.state.selectedOrders) {
      keyword_array.push(this.state.selectedOrders[index]['order_id']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/uncancelorderlist`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        order_id: keyword_array
      }
    });

    this.setState({loading: true});
    req.then(this.unCancelOrderListSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  unCancelOrderListSuccess = (resp) => {
    this.setState({ loading: false });
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    
    // this.context.snackDisplay("Un-cancel selected orders success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Un-cancel selected orders success.");
    window.location.reload();
  }
  addComment = (newComment) => {
    if (newComment.type === "text") {
      this.addCommentText(newComment.text);
    } else {
      this.uploadPdf(newComment.formData);
    }
  }
  addCommentText = (comment_text) => {
    let order_id = this.state.singleOrder.order.order_id;
    let req_data = {
      target_id: order_id,
      type: "order",
      comment: comment_text
    };

    let req = axios({
      method: 'POST',
      url: `${utils.getBaseUrl('customer')}/comment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data,
    });

    this.setState({ loading: true });
    req.then(this.addCommentTextSuccess.bind(this, req_data)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  addCommentTextSuccess = (req_data, resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add order comment success.");

      let newOrder = Object.assign({}, this.state.singleOrder);
      newOrder.comment = this.state.singleOrder.comment.concat([{
        comment: req_data.comment,
        date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        username: localStorage.getItem("username"),
        fullname: "",
      }]);
      this.setState({singleOrder: newOrder});
      this.setState({ dialog: "" });
    } else {
      this.context.alert("Add order comment error!");
    }
  }
   // upload comment pdf
  uploadPdf = (form_data) => {
    let order_id = this.state.singleOrder.order.order_id;
    form_data.append('target_id', order_id);
    form_data.append('type', "order");

    let req = axios({
      method: 'post',
      dataType: 'json',
      processData: false,
      contentType: false,
      cache: false,
      url: `${utils.getBaseUrl('customer')}/import/document`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: form_data,
    });

    this.setState({ loading: true, errors: [] });
    req.then(this.uploadPdfSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  uploadPdfSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add order comment success.");

      this.setState({comment: this.state.singleOrder.comment.concat([{
        comment: resp.data,
        date: moment.utc(),
        username: localStorage.getItem("username"),
        fullname: "",
      }])});
      this.setState({ dialog: "" });
    } else {
      this.context.alert("Upload PDF comment error!");
    }
  }
  updateLineItems = (newItems) => {
    let order_type = this.state.singleOrder.order.order_type;
    let order_id = this.state.singleOrder.order.order_id;

    newItems = newItems.map((elem)=>{
      // set lineitem price according to order type
      if (!elem.lineitem_id) {
        if (order_type === 'wholesale' || order_type === 'whole-sale' || order_type === 'edi') {
          elem.item_price = elem.wholesale_value;
        } else {
          elem.item_price = elem.retail_value;
        }
      }
      if (!elem.quantity) elem.item_status = "CANCELLED";
      return elem;
    });

    // let updateItems = newItems.filter((elem)=>(!elem.prev_quantity || elem.prev_quantity != elem.quantity));
    let updateItems = newItems;

    if (updateItems.length === 0) {
      this.setState({dialog: ''});
      return; // if no change was made, don't make ajax call
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderitems`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      // only pass changed items and new items. For item quantity not changed, don't pass
      data: {
        order_id: order_id,
        item: updateItems
      },
    });

    this.setState({loading: true});
    req.then(this.updateLineItemsSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  updateLineItemsSuccess = (resp) => {
    this.setState({loading: false});

    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    
    this.context.snackDisplay("Order item update success.");

    let newOrder = Object.assign({}, this.state.singleOrder);
    newOrder.item = resp.data;
    this.setState({
      singleOrder: newOrder,
      dialog: '',
    });
  }

  validateSearchForm = () => {
    // let searchBy = this.searchByInput ? this.searchByInput.value : '';
    let searchBy = this.state.searchBy;
    let searchFor = this.searchForInput ? this.searchForInput.value : '';  
    let dateFrom = this.searchDateFromInput ? this.searchDateFromInput.value : '';  
    let dateTo = this.searchDateToInput ? this.searchDateToInput.value : '';  
    let err = "";
    if (searchBy === 'date') {
      if (!dateFrom) err += "Date From input field is empty. \n";  
      if (!dateTo) err += "Date To input field is empty. \n";  
    } else {
      if (!searchFor) err += "Search For input field is empty. \n";  
    }
    
    if (err) {
      this.context.alert(err);
      return;
    }

    // if no error, submit search
    if (searchBy !== 'date') this.searchOrder();
    else this.searchOrderByDate();
  }
  applyFilter = (newVal, key) => {
    let newDisplayFilter = Object.assign({}, this.state.displayFilters);
    newDisplayFilter[key] = newVal;
    if (this.state.renderingAjax) { // if displaying order page, use backend filter
      if (this.updatePage) this.updatePage(0); // if ajax filter changes, reset to page 1
      // this.filterOrderByPage({[key]: newDisplayFilter[key]});
      this.filterOrderByPage(newDisplayFilter);
    } else {
      this.setState({displayFilters: newDisplayFilter, selectedOrders: {}});
    }
  }
  filterOrderByPage = (newDisplayFilter) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderfilter`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      // data: Object.assign({page: this.current_page}, newDisplayFilter)
      data: Object.assign({page: 0}, newDisplayFilter)
    });
    this.current_page = 0;

    this.setState({loading: true});
    req.then(this.filterOrderByPageSuccess.bind(this, newDisplayFilter)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  filterOrderByPageSuccess = (newDisplayFilter, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({orderList: resp.data, displayFilters: Object.assign(this.state.displayFilters, newDisplayFilter), selectedOrders: {}});

    if (resp.data.length == 0) {
      this.context.alert("No data found.");
      return;
    } 
  }
  onSelectedOrdersChange = (newSelected) => (this.setState({selectedOrders: newSelected}))
  onBackToListClick = () => {
    // const {singleOrder, orderList} = this.state;
    // let newOrderList = orderList.map((elem)=>{return (elem.order_id === singleOrder.order_id) ? singleOrder : elem});
    // this.setState({singleOrder: null, orderList: newOrderList});
    this.setState({singleOrder: null});
    utils.scrollToTop();
  }

  getFilteredOrders = (displayFilters) => {
    if (!displayFilters) displayFilters = this.state.displayFilters;

    return this.state.orderList.filter((elem)=>{
      return displayFilters['status'][elem['status']] !== false && displayFilters['type'][elem['order_type']] !== false;
    });
  }

  renderOrderList = () => {
    const {classes} = this.props;
    const { singleOrder, selectedOrders, displayFilters, renderingAjax, actionsAnchor } = this.state;
    if (singleOrder) return;

    let rows = this.getFilteredOrders();

    let columns = [
      {
        key: 'order_key',
        label: 'OrderID',
        width: 'auto',
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={this.loadOrderByID.bind(this, row.order_id)}>{val}</Button>
          );
        }
        // colDesc: 'This is the unique identifier of product, only allow numbers, string, underscore and hyphen',
      },
      {
        key: 'order_id',
        label: 'Barcode',
        width: 100,
        // colDesc: 'This is the unique identifier of product, only allow numbers, string, underscore and hyphen',
      },
      {
        key: 'status',
        label: 'Status',
      },
      // {
      //   key: 'method',
      //   label: 'Method',
      //   contentNoWrap: true,
      //   render: utils.convertShippingMethod
      // },
      {
        key: 'order_type',
        label: 'OrderType',
        contentNoWrap: true
      },
      {
        key: 'system_date',
        label: 'ReceiveDate',
        contentNoWrap: true,
        render: utils.localizeDate
      }
    ];

    // filter options
    let statusOptions = [];
    for (let filterKey in displayFilters['status']) {
      let option = statusMap[filterKey]; 
      statusOptions.push({selected: displayFilters['status'][filterKey], value: option.value, label: option.label});
    }
    let typeOptions = [];
    for (let filterKey in displayFilters['type']) {
      let option = typeMap[filterKey]; 
      typeOptions.push({selected: displayFilters['type'][filterKey], value: option.value, label: option.label});
    }

    let orderListTable = (
      <AjaxTable
        rows={rows}
        // rowHeight={50} 
        columns={columns}
        rowSelection={{
          selected: selectedOrders, // since ajax table will not have sort by, data array index will always work for selection
          handleSelectedChange: this.onSelectedOrdersChange
        }}
        handlePageChange={this.loadOrderByPage}
      />
    );
    if (!renderingAjax) {
      orderListTable = (
        <NewDataTable
          rows={rows}
          // rowHeight={50} 
          maxHeight={500}
          columns={columns}
          rowSelection={{
            selected: selectedOrders, // since ajax table will not have sort by, data array index will always work for selection
            handleSelectedChange: this.onSelectedOrdersChange
          }}
        />
      );
    }

    let listOrderActions = [];
    listOrderActions.push({
      label: 'Export',
      onClick: this.exportOrders
    }); // export order is for all Role types
    listOrderActions.push({label: 'Edit Detail', onClick: ()=>{
      this.setState({ actionsAnchor: null});
      if (_.isEmpty(selectedOrders)) {
        this.context.alert('No order selected.');
        return;
      }
      this.setState({dialog: "editListDetail" })
      }
    }); // also allow client to edit list detail
    if (utils.getRoleType() == 'customer') {
      // should put actions only for Customer Role, here
    } else {
      // Warehuose actions
      // Create Labels
      listOrderActions.push({label: 'Create Label', onClick: ()=>{
          this.setState({ actionsAnchor: null});
          if (_.isEmpty(selectedOrders)) {
            this.context.alert('No order selected.');
            return;
          }
          this.context.confirm("Are you sure to create labels for selected orders?", this.newListLabel);
       }
      });
      // listOrderActions.push({label: 'Edit Detail', onClick: ()=>{
      //   this.setState({ actionsAnchor: null});
      //   if (_.isEmpty(selectedOrders)) {
      //     this.context.alert('No order selected.');
      //     return;
      //   }
      //   this.setState({dialog: "editListDetail" })
      //   }
      // });
      listOrderActions.push({label: 'Edit Address', onClick: ()=>{
          this.setState({ actionsAnchor: null});
          if (_.isEmpty(selectedOrders)) {
            this.context.alert('No order selected.');
            return;
          }
          this.setState({dialog: 'editListAddress'});
        }
      });
      listOrderActions.push({label: 'Update Tracking', onClick: ()=>{
          this.setState({ actionsAnchor: null});
          if (_.isEmpty(selectedOrders)) {
            this.context.alert('No order selected.');
            return;
          }
          this.setState({dialog: 'editPalletTracking' })
        }
      });
      listOrderActions.push({label: 'Send ASN', onClick: ()=>{
          this.setState({ actionsAnchor: null});
          if (_.isEmpty(selectedOrders)) {
            this.context.alert('No order selected.');
            return;
          }
          this.context.confirm("Are you sure to send ASN for selected orders?", this.sendListASN);
        }
      });
      listOrderActions.push({label: 'Mark Released', onClick: ()=>{
          this.setState({ actionsAnchor: null});
          if (_.isEmpty(selectedOrders)) {
            this.context.alert('No order selected.');
            return;
          }
          this.releaseOrders();
          // this.context.confirm("Are you sure to mark selected orders released?", this.releaseOrders);
        }
      });
      // listOrderActions.push({label: 'Un-Cancel', onClick: ()=>{
      //     this.setState({ actionsAnchor: null});
      //     if (_.isEmpty(selectedOrders)) {
      //       this.context.alert('No order selected.');
      //       return;
      //     }
      //     this.context.confirm("Are you sure to cancel selected orders?", this.unCancelOrderList);
      //   }
      // });
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div className={classes.toolBar}>
            <ButtonGroup>
              <MultiSelect
                title="Status"
                listItems={statusOptions}
                handleSubmit={(val)=>{this.applyFilter(val, 'status')}}
              />

              <MultiSelect
                title="Type"
                listItems={typeOptions}
                handleSubmit={(val)=>{this.applyFilter(val, 'type')}}
              />
            </ButtonGroup>

            {
              utils.getRoleType() == 'customer' && (
                <div className={classes.headerToolBar} style={{flexGrow: '2'}}>
                  <ButtonGroup>
                    {listOrderActions.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
                  </ButtonGroup> 
                </div>
              )
            }
            
            {
              utils.getRoleType() == 'warehouse' && <div style={{flexGrow: '2'}} className={classes.headerToolBar}>
              {/* <Hidden lgDown>
                <ButtonGroup >
                  {listOrderActions.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
                </ButtonGroup>
              </Hidden> */}
              
              {/* <Hidden xlUp>
                <Button onClick={(e)=>{this.setState({actionsAnchor: this.state.actionsAnchor ? null : e.currentTarget});}}>More Actions</Button>
                
                <Popover
                    open={Boolean(actionsAnchor)}
                    anchorEl={actionsAnchor}
                    onClose={()=>this.setState({actionsAnchor: null})}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                  }}
                  >
                      {listOrderActions.map((btn)=> <MenuItem key={btn.label} onClick={btn.onClick}>{btn.label}</MenuItem>)}
                  </Popover>
              </Hidden> */}

              {/* Always show dropdown action select, not full button list */}
              <Button onClick={(e)=>{this.setState({actionsAnchor: this.state.actionsAnchor ? null : e.currentTarget});}}>More Actions</Button>
                
              <Popover
                  open={Boolean(actionsAnchor)}
                  anchorEl={actionsAnchor}
                  onClose={()=>this.setState({actionsAnchor: null})}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                >
                    {listOrderActions.map((btn)=> <MenuItem key={btn.label} onClick={btn.onClick}>{btn.label}</MenuItem>)}
                </Popover>
            </div>
            }
            
            <Button style={{float: 'right'}} color="secondary" variant="contained" onClick={()=>{
              if (_.isEmpty(selectedOrders)) {
                this.context.alert('No order selected.');
                return;
              }
              this.context.confirm("Are you sure to cancel selected orders?", this.cancelOrderList);
            }}>Cancel</Button>
          </div>

          {orderListTable}
        </CardContainer>
      </GridItem>
    );
  }
  renderSingleOrder = () => {
    const {classes} = this.props;
    const {singleOrder, singleActionsAnchor} = this.state;
    if (!singleOrder) return null;
    const {order: orderDetail, address, item: lineItems, return_label, hostedcheckout, action_log: actionLog, comment, carton, shipment} = singleOrder;

    let commentRows = comment ? Array.from(comment) : [];
    commentRows.push({
      fullname: localStorage.getItem('username'),
      add_new: 'text',
      comment: "",
    });
  
    let order_status = orderDetail.order_status;
    let singleOrderActions = [];
    singleOrderActions.push({
      label: 'Edit',
      onClick: ()=>{this.setState({dialog: 'editOrderDetail'})}
    }); 
    if (printPackingSlipStatus[order_status]) {
      singleOrderActions.push({
        label: 'Packing Slip',
        onClick: this.loadPackingSlip
      }); 
    }
    if (invoiceStatus[order_status]) {
      singleOrderActions.push({
        label: 'Invoice',
        onClick: this.loadOrderInvoice
      }); 
      singleOrderActions.push({
        label: 'Return Label',
        onClick: ()=>{
          // if (return_label && return_label.length > 0) {
          //   // has return label
          //   this.setState({dialog: 'returnLabel'});
          // } else {
          //   // don't have one, create a new one
          //   this.context.confirm("Do you want to create a return label for this order?", this.getReturnLabel);
          // }
          this.context.confirm("Do you want to create a return label for this order?", this.getReturnLabel);
        }
      }); 
    }
    // if (invoiceStatus[order_status] && (singleOrder.order.order_type === 'edi' || singleOrder.order.order_type === 'dropship')) {
    //   singleOrderActions.push({
    //     label: 'UCC Label',
    //     onClick: this.printUCCLabel,
    //   });
    // }
    if (invoiceStatus[order_status]) {
      singleOrderActions.push({
        label: 'Carton Labels',
        onClick: this.getCartonLabel,
      });
    }

    let singleOrderAcistionGroup = null;
    if (singleOrderActions.length > 1) {
      singleOrderAcistionGroup = <React.Fragment>
          <Hidden lgDown>
            <ButtonGroup >
              {singleOrderActions.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
            </ButtonGroup>
          </Hidden>
        
          <Hidden xlUp>
            <ButtonGroup >
              <Button color="bxzDefault" variant="contained" onClick={(e)=>{this.setState({singleActionsAnchor: this.state.singleActionsAnchor ? null : e.currentTarget});}}>More Actions</Button>      
            </ButtonGroup>
              
            <Popover
              open={Boolean(singleActionsAnchor)}
              anchorEl={singleActionsAnchor}
              onClose={()=>this.setState({singleActionsAnchor: null})}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            >     
              {singleOrderActions.map((btn)=> <MenuItem key={btn.label} onClick={()=>{
                this.setState({singleActionsAnchor: null});
                btn.onClick();
              }}>{btn.label}</MenuItem>)}
            </Popover>
          </Hidden>
      </React.Fragment>;
    } else {
      singleOrderAcistionGroup = (
        <ButtonGroup >
          {singleOrderActions.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
        </ButtonGroup>
      );
    }

    return (
      <React.Fragment>
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <Typography variant="h6" style={{marginBottom: '.5rem'}}>
              Order Detail
              <div className={classes.headerToolBar}>
                {!this.displayDB && <Button color="bxzDefault" variant="contained" onClick={this.onBackToListClick}>Go Back</Button>}

                {singleOrderAcistionGroup}
              </div>
            </Typography>
           
            <KeyValueTable
              dataObj={orderDetail}
              rowHeight='auto'
              propertyList={[
                {
                  key: 'customer',
                  label: 'Customer',
                },
                {
                  key: 'warehouse',
                  label: 'Warehouse',
                  render: (val, key, row) => {
                    // right now turn off in-place warehuose edit, do it in the dialog first
                    return row.warehouse;
                  }
                },
                {
                  key: 'order_key',
                  label: 'Order ID',
                },
                {
                  key: 'order_id',
                  label: 'BXZ ID',
                },
                {
                  key: 'package_id',
                  label: 'Thirdparty ID',
                },
                {
                  key: 'order_status',
                  label: 'Status',
                },
                {
                  key: 'method',
                  label: 'Shipping Method',
                  render: utils.convertShippingMethod
                },
                {
                  key: 'order_type',
                  label: 'Order Type',
                },
                {
                  key: 'incoterms',
                  label: 'Incoterms',
                },
                {
                  key: 'carrier_account',
                  label: 'Third Party Carrier Account #',
                },
                {
                  key: 'start_date',
                  label: 'Start Date',
                },
                {
                  key: 'ship_date',
                  label: 'Ship Date',
                },
                {
                  key: 'cancel_date',
                  label: 'Cancel Date',
                },
                {
                  key: 'saturday_delivery',
                  label: 'Saturday Delivery',
                  render: (val) => {return parseInt(val) === 1 ? 'YES' : 'NO'}
                },
                {
                  key: 'signature_required',
                  label: 'Signature Required',
                  render: (val) => {return parseInt(val) === 1 ? 'YES' : 'NO'}
                },  
                {
                  key: 'vendor',
                  label: 'Vendor',
                },
                {
                  key: 'carrier',
                  label: 'Carrier',
                },
                {
                  key: 'gift_message',
                  label: 'Slipnote',
                },
                // {
                //   key: 'packing_instruction',
                //   label: 'Packing Instruction',
                //   colHeight: 80,
                // },
                {
                  key: 'released',
                  label: 'Released',
                },
              ]}
            />
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <Grid container spacing={2}>
            <GridItem xs={12}>
              <CardContainer>
                <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Additional Detail</Typography>
                <KeyValueTable
                  dataObj={orderDetail}
                  propertyList={[
                    {
                      key: 'batch_id',
                      label: 'Batch ID',
                    },
                    {
                      key: 'system_dt',
                      label: 'Received Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'dropped_dt',
                      label: 'Dropped Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'batch_dt',
                      label: 'Batched Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'picked_dt',
                      label: 'Picked Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'shippped_date',
                      label: 'Shippped Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'pickup_dt',
                      label: 'Package Pick Up Date',
                      render: utils.localizeDate
                    },
                    {
                      key: 'delivery_dt',
                      label: 'Package Delivery Date',
                      render: utils.localizeDate
                    },
                    // {
                    //   key: 'zone',
                    //   label: 'Zone',
                    // },
                    // {
                    //   key: 'order_resource',
                    //   label: 'Order Resource',
                    // },
                  ]}
                />
              </CardContainer>
            </GridItem> 

            <GridItem xs={12}>
              <CardContainer>
                <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                  Shipping Address
                  <div className={classes.headerToolBar}>
                    <Button onClick={()=>{this.setState({dialog: 'editAddress'})}}>Edit</Button>
                  </div>
                </Typography>
                <KeyValueTable
                  dataObj={address}
                  propertyList={[
                    {
                      key: 'name',
                      label: 'Full Name',
                    },
                    {
                      key: 'company',
                      label: 'Company',
                    },
                    {
                      key: 'address1',
                      label: 'Address Line 1',
                    },
                    {
                      key: 'address2',
                      label: 'Address Line 2',
                    },
                    {
                      key: 'city',
                      label: 'City',
                    },
                    {
                      key: 'state',
                      label: 'Province/State',
                    },
                    {
                      key: 'zipcode',
                      label: 'Postal Code',
                    },
                    {
                      key: 'country',
                      label: 'Country Code',
                    },
                    {
                      key: 'email',
                      label: 'Email',
                    },
                    {
                      key: 'phone',
                      label: 'Phone',
                    },
                  ]}
                />
              </CardContainer>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem xs={12} sm={12}>
          <CardContainer>

            <Typography variant="h6" style={{marginBottom: '.5rem'}}>
              Lineitems
              {
                (itemEditStatus[order_status]) && <div className={classes.headerToolBar}>
                  <Button onClick={()=>{this.setState({dialog: 'editItem'})}}>Edit</Button>
                </div>
              }
            </Typography>

            <NewDataTable
              rows={this.lineItemsOnDisplay(lineItems) || []}
              noPagination
              maxHeight={500}
              columns={[
                {
                  key: 'index',
                  label: 'Index',
                  render: (vale, key, row, index)=>{
                    if (row.total) return 'Total:';
                    else return index+1;
                  }
                }, 
                {
                  key: 'sku',
                  label: 'SKU',
                  contentNoWrap: true,
                }, 
                {
                  key: 'upc',
                  label: 'UPC',
                }, 
                {
                    key: 'item_name',
                    label: 'Item Name',
                    width:  250,
                }, 
                {
                    key: 'monogram',
                    label: 'Monogram',
                },
                {
                  key: 'gift_message',
                  label: 'Slipnote',
                },
                {
                  key: 'quantity',
                  label: 'Quantity',
                },
                {
                  key: 'item_price',
                  label: 'Price',
                },
                {
                  key: 'item_status',
                  label: 'Status',
                },
                {
                  key: 'hs_code',
                  label: 'HS Code',
                },
                {
                  key: 'updated_dt',
                  label: 'Date/Time',
                  contentNoWrap: true,
                  render: (val, key, row, index)=>{
                    if (row.total) return '';
                    else return utils.localizeDate(val);
                  }
                },
              ]}
            />
          </CardContainer>
        </GridItem>

        {(carton && carton.length > 0) &&
          <GridItem xs={12} sm={12}>
            <CardContainer>
            <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Cartons</Typography>
              <NewDataTable
                rows={carton}
                // rowHeight={42}
                noPagination
                maxHeight={500}
                columns={[
                  {
                    key: 'carton_item',
                    label: '',
                    contentNoWrap: true,
                    collapsible: true,
                    renderCollapsedContent: (val)=>{
                      return (
                        <React.Fragment>
                          <Typography variant="h6" gutterBottom component="div" style={{textAlign: 'left', fontWeight: 'bold'}}>Carton Items</Typography>
                          <NewDataTable
                            rows={val}
                            noPagination
                            columns={[
                              {
                                key: 'sku',
                                label: 'SKU',
                              }, 
                              {
                                key: 'upc',
                                label: 'UPC',
                              },
                              {
                                key: 'item_name',
                                label: 'Item Name',
                              }, 
                              {
                                key: 'quantity',
                                label: 'Quantity',
                              },
                            ]}
                          />
                        </React.Fragment>
                      );
                    },
                    render: ()=>''
                  }, 
                  {
                    key: 'index',
                    label: 'Index',
                    render: (vale, key, row, index)=>{return index+1}
                  }, 
                  {
                    key: 'carton_id',
                    label: 'Carton ID',
                  },
                  {
                    key: 'carrier',
                    label: 'Carrier',
                  }, 
                  {
                    key: 'tracking',
                    label: 'Tracking #',
                    render: (val, key, row) => {
                      let url = '';
                      switch (row.carrier?.toUpperCase()) {
                        case 'UPS':
                          url = `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${val || ''}`;
                          break;
                        case 'FEDEX':
                          if (val && val.includes('BOX3060')) url = `https://fictracking.fedex.com/?MyFICNumber=${val || ''}`;
                          else url = `https://www.fedex.com/fedextrack/?trknbr=${val || ''}`;
                          break;
                        case 'USPS':
                          url = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${val || ''}`;
                          break;
                        case 'DHLGLOBALMAIL':
                          url = `https://webtrack.dhlglobalmail.com/orders?trackingNumber=${val || ''}`;
                          break;
                        case 'ONTRAC':
                            url = `https://www.ontrac.com/tracking/?number=${val || ''}`;
                            break;
                        default:
                          // carrier is Boxzooka or other un-supported carrier, don't show tracking link
                          url = '';
                      }
                      if (url) return <Button color="default" onClick={()=>{let w = window.open(url);if (!w) {this.context.alert('Please allow pop up window.');}}}>{val}</Button>;
                      else return val || '';
                    }
                  }, 
                  {
                      key: 'weight',
                      label: 'Weight',
                  }, 
                  {
                    key: 'b2b_pallet',
                    label: 'B2B Pallet',
                  },
                  {
                    key: 'date',
                    label: 'Date',
                    contentNoWrap: true,
                    render: utils.localizeDate
                  },
                  {
                    key: 'action',
                    label: 'Actions',
                    render: (val, key, row, index) => {
                      if (order_status != 'SHIPPED') return null;
                      return (
                        <React.Fragment>
                           { this.context.roleType.get() == 'warehouse' && <IconButton onClick={()=>{this.setState({dialog: 'editCarton', activeCartonIndex: index})}} variant="fab" aria-label="PDF" size="small">
                            <Edit />
                            </IconButton>}

                            {/* <IconButton color="secondary" onClick={()=>{this.context.confirm('Are you sure to cancel this carton?', this.cancelCarton.bind(this, index))}} variant="fab" aria-label="Add" size="small" style={{marginLeft: ".5rem"}}>
                              <DeleteForever/>
                            </IconButton> */}
                        </React.Fragment>
                      );
                    }
                  },
                ]}
              />
            </CardContainer>
          </GridItem>   
        }

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Action Logs</Typography>
            <NewDataTable
              rows={actionLog || []}
              noPagination
              maxHeight={500}
              columns={[
                {
                  key: 'fullname',
                  label: 'User',
                },{
                  key: 'notes',
                  label: 'Notes'
                }, {
                  key: 'created_dt',
                  label: 'Date/Time',
                  render: utils.localizeDate
                }
              ]}
            />
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <Grid container spacing={2}>
            {
              (utils.getRoleType() != 'customer' || showCustomerComments[localStorage.getItem('customer_id')]) &&
              <GridItem xs={12}>
                <CardContainer>
                  <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Comments</Typography>
                  <NewDataTable
                    noPagination
                    // rowHeight={38}
                    rows={commentRows}
                    maxHeight={400}
                    columns={[
                      {
                        // key: 'fullname',
                        // label: 'User',
                        key: 'username',
                        label: 'User',
                      },{
                        key: 'comment',
                        label: 'Comment',
                        render: (val, key, row) => {
                          if (row.add_new) {
                            return (
                              <IconButton onClick={()=>{this.setState({dialog: 'addComment'})}} size="small" style={{backgroundColor: "#3f51b5", marginLeft: ".5rem"}} variant="fab" aria-label="Add">
                                <Add style={{color: 'white'}}/>
                              </IconButton>
                              // <IconButton onClick={()=>{this.setState({dialog: 'addComment'})}} variant="fab" aria-label="PDF" size="small">
                              //   <Add />
                              // </IconButton>
                            );
                          } else {
                            let content = val ? val : '';
                            if (content.includes("https://")) {
                              let btn_text = "See PDF";
                              if (content.includes(".pdf")) 
                              {
                                btn_text = "See PDF";
                              } else 
                              {
                                btn_text = "See Image";
                              }
                              let file_name = content.split("import/");
                              if (file_name.length > 1)
                              {
                                btn_text = utils.removeFilePrefix(file_name[1]);
                              } 

                              return (<Button onClick={()=>{
                                  let newTab = window.open(content);
                                  if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
                              }}>{btn_text}</Button>);
                            }
                            return content;
                          }
                        }
                      }, {
                        key: 'date',
                        label: 'Date/Time',
                        render: utils.localizeDate
                      }
                    ]}
                  />
                </CardContainer>
              </GridItem> 
            }
        
            {
              (return_label && return_label.length > 0 && invoiceStatus[order_status]) && 
              <GridItem xs={12}>
                <CardContainer>
                  <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                    Return Label
                  </Typography>
                  <NewDataTable
                    rows={return_label}
                    rowHeight={48}
                    noPagination
                    columns={[
                      {
                        key: 'tracking',
                        label: 'Tracking',
                      }, 
                      {
                        key: 'tracking_label',
                        label: 'Return Label',
                        render: (val, key, row) => {
                          let content = val ? val : '';
                          if (content.includes("https://") || content.includes("http://")) {
                            return (<Button onClick={()=>{
                                let newTab = window.open(content);
                                if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
                            }}>See Label</Button>);
                          }
                          return content;
                        }
                      }, 
                    ]}
                  />
                </CardContainer>
              </GridItem>
            }
      
          </Grid>
        </GridItem>
       
      </React.Fragment>
    );  
  }
  renderDialog = () => {
    const {dialog, singleOrder, activeCartonIndex} = this.state;
    switch (dialog) {
      case 'returnLabel':
        let last_label = singleOrder.return_label[singleOrder.return_label.length - 1]; // display last return label, newest one
        return <ReturnLabel
          // returnLabelUrl={singleOrder.return_label[0][0]}
          // returnTracking={singleOrder.return_label[0][1]}
          returnLabelUrl={last_label.tracking_label}
          returnTracking={last_label.tracking}
          createLabel={this.getReturnLabel}
          handleSubmit={()=>{this.setState({dialog: ''})}}
          handleClose={()=>{this.setState({dialog: ''})}}
        />;
      case 'addComment':
        return (
          <AddComment 
            handleSubmit={(newComment)=>{this.addComment(newComment)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editOrderDetail':
        return (
          <EditOrderDetail 
            order={singleOrder.order}
            methodList={this.methodList}
            handleSubmit={(newOrder)=>{this.updateOrderDetail(newOrder)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editItem':
        return (
          <EditOrderItem
            // lineItems={singleOrder.item.filter((elem)=>elem.item_status !== 'CANCELLED')}
            lineItems={singleOrder.item.map((elem)=>{
              if (elem.item_status == 'CANCELLED') elem.quantity = 0; 
              return elem;
            })}
            handleSubmit={(newLineItems)=>{
              this.updateLineItems(newLineItems);
            }}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editCarton':
        return (
          <EditOrderShipment 
            shipment={singleOrder.carton[activeCartonIndex]}
            handleSubmit={(newCarton)=>{this.updateOrderCarton(newCarton)}}
            handleClose={()=>{this.setState({dialog: '', activeCartonIndex: -1})}}
          />
        );
        break;
      case 'editShipment':
        return (
          <EditOrderShipment 
            shipment={singleOrder.shipment}
            handleSubmit={(newShipment)=>{this.updateOrderShipment(newShipment)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editAddress':
        return (
          <EditOrderAddress 
            address={singleOrder.address}
            countryList={this.countryList}
            handleSubmit={(newAddress)=>{this.updateOrderAddress(newAddress)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editListDetail':
        return (
          <EditListOrderDetail 
            methodList={this.methodList}
            handleSubmit={(newDetail)=>{this.updateListDetail(newDetail)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editListAddress':
        return (
          <EditOrderAddress 
            address={{
              name: '', company: '', address1: '', address2: '', state: '', city: '', zipcode: '', country: 'US', email: '', phone: ''
            }}
            countryList={this.countryList}
            // need to confirm before submitting change
            // multi order, non revertable
            handleSubmit={this.onListAddressSubmit}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editPalletTracking':
        return (
          <EditPalletTracking 
            handleSubmit={(newTracking)=>{this.updateListPalletTracking(newTracking)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
    }
  }

  lineItemsOnDisplay = (lineItems) => {
    let total = {total: true, sku: 0, quantity: 0};
    if (!lineItems || lineItems.length == 0) return lineItems;
    else {
      let sku_map = {};
      lineItems.map((elem)=>{
        if (elem.item_status !== "CANCELLED") {
          total.quantity += parseInt(elem.quantity) || 0;
          sku_map[elem.sku] = elem.sku;
        }
      });
      total.sku = Object.keys(sku_map).length;
      return lineItems.concat([total]);
    }
  }

  render() {
    const {classes} = this.props; 
    const {loading, searchBy} = this.state;

    // let searchBy = this.searchByInput ? this.searchByInput.value : ''

    let searchKeywordInput = null;
    switch (searchBy)  {
      case 'date':
        searchKeywordInput = (
          <Grid container spacing={1}>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText='Date From'
                labelProps={{shrink: true}}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  style: {marginTop: 0}
                }}
                inputProps={{
                  type: 'date',
                  inputRef: (el)=>{this.searchDateFromInput = el},
                  defaultValue: "",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>    
              <CustomInput
                labelText='Date To'
                labelProps={{shrink: true}}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  style: {marginTop: 0}
                }}
                inputProps={{
                  type: 'date',
                  inputRef: (el)=>{this.searchDateToInput = el},
                  defaultValue: "",
                }}
              />
            </GridItem>
          </Grid>
        );
        break;
      case 'all':
        searchKeywordInput = (
          <CustomInput
            labelText='Search For'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              style: {marginBottom: 0},
              className: this.props.classes.customInput
            }}
            inputProps={{
              defaultValue: "",
              inputRef: (el)=>{this.searchForInput = el},
            }}
          />
        );
        break;
      default:
        searchKeywordInput = (
          <CustomInput
            labelText='Search For'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              style: {marginBottom: 0},
              className: this.props.classes.customInput
            }}
            inputProps={{
              defaultValue: "",
              inputRef: (el)=>{this.searchForInput = el},
              placeholder: 'Use Linebreak to separate multiple keywords',
              multiline: true,
              rows: 3
            }}
          />
        );
    }

    return (
      <Grid container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
              
        {this.renderDialog()}

        <GridItem xs={12} sm={6} md={3}>
          <CardContainer>
            <div>
              <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Search By</InputLabel>
                <Select
                  value={searchBy}
                  onChange={(e)=>this.setState({searchBy: e.target.value})}
                  // defaultValue="order"
                  // inputRef={(el)=>{this.searchByInput = el}}
                >
                  <MenuItem value='order'>Order ID</MenuItem>
                  <MenuItem value='barcode'>Barcode</MenuItem>
                  <MenuItem value='tracking'>Tracking #</MenuItem>
                  <MenuItem value='all'>Other</MenuItem>
                  <MenuItem value='date'>Date</MenuItem>
                </Select>
              </FormControl>
              
              {searchKeywordInput}
              
              <Button className={classes.button} onClick={this.validateSearchForm}>Search</Button>
            </div>
          </CardContainer>
        </GridItem>

        <Hidden xsDown>
          <GridItem md={9} />
        </Hidden>

        {this.renderSingleOrder()}
        {this.renderOrderList()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManageOrder);
