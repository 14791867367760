import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function NewPOReservation(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const { client_view, handleSubmit, handleClose, customer_options, default_date_input, default_time_input } = props;
  const [reservation, setReservatopm] = useState({
    date: default_date_input || '',
    time: default_time_input || '',
    type: 'Inbound',
    carrier: '',
    customer: client_view ? localStorage.getItem('customer_id') : '',
    notes: '',
    pallets: '',
    type_key: ''
  });

  function onReservationChange(key, newVal) {
    let newReservation = Object.assign({}, reservation);
    newReservation[key] = newVal;
    setReservatopm(newReservation);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Type</InputLabel>
          <Select
            onChange={(e)=>{onReservationChange('type', e.target.value)}}
            value={reservation.type}
          >
            <MenuItem value='Inbound'>Inbound</MenuItem>
            <MenuItem value='Outbound'>Outbound</MenuItem>
          </Select>
        </FormControl>
         
        <CustomInput
          labelText='Type Key'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onReservationChange('type_key', e.target.value)},
            value: reservation.type_key,
          }}
        />

        {
          !client_view && <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Customer</InputLabel>
            <Select
              onChange={(e)=>{onReservationChange('customer', e.target.value)}}
              value={reservation.customer}
            >
              {customer_options.map((elem)=><MenuItem key={elem.customer_id} value={elem.customer_id}>{elem.shortname}</MenuItem>)}
            </Select>
          </FormControl>
        }

        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Carrier</InputLabel>
          <Select
            onChange={(e)=>{onReservationChange('carrier', e.target.value)}}
            value={reservation.carrier}
          >
            <MenuItem value='FedEx'>FedEx</MenuItem>
            <MenuItem value='UPS'>UPS</MenuItem>
            <MenuItem value='USPS'>USPS</MenuItem>
            <MenuItem value='Truck'>Truck</MenuItem>
            {/* <MenuItem value='container-20'>Container - 20 Feet</MenuItem>
            <MenuItem value='container-40'>Container - 40 Feet </MenuItem> */}
          </Select>
        </FormControl>

        <CustomInput
          labelText='Number of Pallets'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onReservationChange('pallets', e.target.value)},
            value: reservation.pallets,
            type: 'number',
            inputProps: {
              min: 0, step: 1
            }
          }}
        />

        <CustomInput
          labelText='Date'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onReservationChange('date', e.target.value)},
            value: reservation.date,
            type: 'date',
            inputProps: {
              min: moment().format("YYYY-MM-DD"),
            }
          }}
        />

        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Time</InputLabel>
          <Select
            onChange={(e)=>{onReservationChange('time', e.target.value)}}
            value={reservation.time}
          >
            <MenuItem value='08:00'>08:00</MenuItem>
            <MenuItem value='09:00'>09:00</MenuItem>
            <MenuItem value='10:00'>10:00</MenuItem>
            <MenuItem value='11:00'>11:00</MenuItem>
            <MenuItem value='12:00'>12:00</MenuItem>
            <MenuItem value='13:00'>13:00</MenuItem>
            <MenuItem value='14:00'>14:00</MenuItem>
          </Select>
        </FormControl>

        <CustomInput
          labelText='Notes'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onReservationChange('notes', e.target.value)},
            value: reservation.notes,
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    const { type_key, type, carrier, customer, notes, pallets, date, time } = reservation;
    let err = '';
  
    if (!type) err += 'Reservation Type is required.\n';
    if (!type_key) err += 'Type Key is required.\n';
    if (!carrier) err += 'Carrier is required.\n';
    if (!customer) err += 'Customer is required.\n';
    
    if (!pallets) err += 'Number of Pallets is required.\n';
    if (!parseInt(pallets) || parseInt(pallets) <= 0) err += 'Number of Pallets is should be a integer and should be greater than 0.\n';
    if (!date) err += 'Date is required.\n';
    if (moment(date).day() < 1 || moment(date).day() > 5) err += 'Date is invalid. Should be weekdays (Monday - Friday).\n';
    if (!utils.isDateInRange(date)) err += 'Date is out of range.\n';
    if (!time) err += 'Time is required.\n';
    if (parseInt(time) < 8  || parseInt(time) > 18) err += 'Time is invalid. Should be working hour (between 08:00 and 18:59).\n';

    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit(reservation);
  }

  let title = "New Reservation";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}